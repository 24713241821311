import React, {useRef} from 'react';
import {useLanguageDictionary} from '../../../utilities';
import SVGInline from 'react-svg-inline';
import icons from '../../../assets/images/icons/icons.json';
import {TPatientsListItemProps, TStorageProps} from '../../../types';
import {setModalParameters, setPatient, setPatientsListItem} from '../../../storage/actions';
import {useDispatch, useSelector} from 'react-redux';
import user from '../../../api/requests/patients';

const PatientsListItemComponent: React.FC<TPatientsListItemProps> = ({
                                                                       pk,
                                                                       number,
                                                                       doctorsFullName,
                                                                       gender,
                                                                       byYear,
                                                                       contacts,
                                                                       email,
                                                                       handlerSetShowPatient
                                                                     }) => {
  const {patientsListItem} = useSelector((storage: TStorageProps) => ({patientsListItem: storage.patients.patientsListItem}));
  const {dictionary} = useLanguageDictionary();
  const dispatch = useDispatch();
  const button = useRef(null);

  const showPatientCard = () => {
    user.getPatient(pk)
      .then(
        (data: any) => data.json()
      )
      .then(
        (data: any) => {
          dispatch(setPatient(data));
          dispatch(setModalParameters(true, 'patientCard', {}));
        }
      )
      .catch((error: any) => error);
  }

  return (
    <li
      className={`Patients__table-list-item ${patientsListItem && patientsListItem.pk === pk && 'Patients__table-list-item_selected'}`}
      onClick={(e: any) => {
        if (!e.nativeEvent.path.includes(button.current)) {
          dispatch(setPatientsListItem({pk, doctorsFullName, contacts, email}));
          handlerSetShowPatient(true);
        }
      }}
    >
      <span className="Patients__table-td Patients__table-column">
        {number}
      </span>
      <span className="Patients__table-td Patients__table-column">
        {doctorsFullName.substring(0, doctorsFullName.indexOf('_'))}
      </span>
      <span className="Patients__table-td Patients__table-column">
        {doctorsFullName.substring(doctorsFullName.indexOf('_') + 1, doctorsFullName.lastIndexOf('_'))}
      </span>
      <span className="Patients__table-td Patients__table-column">
        {doctorsFullName.substring(doctorsFullName.length, doctorsFullName.lastIndexOf('_') + 1)}
      </span>
      <span className="Patients__table-td Patients__table-column">
        {gender ? dictionary['M'] : dictionary['W']}
      </span>
      <span className="Patients__table-td Patients__table-column">
        {byYear}
      </span>
      <span className="Patients__table-td Patients__table-column">
        {contacts}
      </span>
      <span className="Patients__table-td Patients__table-column">
        <button
          className="Patients__table-show-card"
          type="button"
          ref={button}
          onClick={() => showPatientCard()}
        >
          <SVGInline
            className="SVGInline_stroke"
            svg={`${icons['file_text']}`}
          />
        </button>
      </span>
    </li>
  );
}

export default PatientsListItemComponent;
