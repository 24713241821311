import api from '../../';

const getPatientsList = () => {
  return new Promise((resolve, reject) => {
    api.get('user/profile/consulted', {})
      .then(
        (response: any) => {
          resolve(response);
        },
        (response: any) => {
          reject(response);
        }
      )
      .catch((error: any) => {
        reject(error);
      });
  });
}

const getPatient = (pk: number) => {
  return new Promise((resolve, reject) => {
    api.get(`user/profile?profileId=${pk}`, {})
      .then(
        (response: any) => {
          resolve(response);
        },
        (response: any) => {
          reject(response);
        }
      )
      .catch((error: any) => {
        reject(error);
      });
  });
}

const sendModifiedPatientData = (patient: any) => {
  return new Promise((resolve, reject) => {
    api.patch(`user/profile/${patient.pk}`, patient)
      .then(
        (response: any) => {
          resolve(response);
        },
        (response: any) => {
          reject(response);
        }
      )
      .catch((error: any) => {
        reject(error);
      });
  });
}

const sendNewPatientData = (patient: any) => {
  return new Promise((resolve, reject) => {
    api.post('user/profile', patient)
      .then(
        (response: any) => {
          resolve(response);
        },
        (response: any) => {
          reject(response);
        }
      )
      .catch((error: any) => {
        reject(error);
      });
  });
}

const findPatient = (email: string) => {
  return new Promise((resolve, reject) => {
    api.get(`users/search?login=${email}`, {})
      .then(
        (response: any) => {
          resolve(response);
        },
        (response: any) => {
          reject(response);
        }
      )
      .catch((error: any) => {
        reject(error);
      });
  });
}

const findPatientData = (id: number) => {
  return new Promise((resolve, reject) => {
    api.get(`user/profile?credId=${id}`, {})
      .then(
        (response: any) => {
          resolve(response);
        },
        (response: any) => {
          reject(response);
        }
      )
      .catch((error: any) => {
        reject(error);
      });
  });
}

const getPatientReceptions = (pk: number) => {
  return new Promise((resolve, reject) => {
    api.get(`user/${pk}/consultations`, {})
      .then(
        (response: any) => {
          resolve(response);
        },
        (response: any) => {
          reject(response);
        }
      )
      .catch((error: any) => {
        reject(error);
      });
  });
}

const sendPatientConsultation = (pk: number, prescription: string) => {
  return new Promise((resolve, reject) => {
    api.post('consultation', {patient_id: pk, prescription})
      .then(
        (response: any) => {
          resolve(response);
        },
        (response: any) => {
          reject(response);
        }
      )
      .catch((error: any) => {
        reject(error);
      });
  });
}

const getAutomaticGraphicKey = (id: number) => {
  return new Promise((resolve, reject) => {
    api.get(`pulse/results/${id}`, {})
      .then(
        (response: any) => {
          resolve(response);
        },
        (response: any) => {
          reject(response);
        }
      )
      .catch((error: any) => {
        reject(error);
      });
  });
}

const sendModifiedPatientConsultation = (patient: any) => {
  return new Promise((resolve, reject) => {
    api.patch(`consultation/${patient.pk}`, patient)
      .then(
        (response: any) => {
          resolve(response);
        },
        (response: any) => {
          reject(response);
        }
      )
      .catch((error: any) => {
        reject(error);
      });
  });
}

const sendNewPatientConsultation = (patient: any) => {
  return new Promise((resolve, reject) => {
    api.post(`consultation`, patient)
      .then(
        (response: any) => {
          resolve(response);
        },
        (response: any) => {
          reject(response);
        }
      )
      .catch((error: any) => {
        reject(error);
      });
  });
}

const getPatientAnalyzes = (pk: number) => {
  return new Promise((resolve, reject) => {
    api.get(`pulse/results?profileId=${pk}`, {})
      .then(
        (response: any) => {
          resolve(response);
        },
        (response: any) => {
          reject(response);
        }
      )
      .catch((error: any) => {
        reject(error);
      });
  });
}

const sendPatientAnalyze = (patient: any) => {
  return new Promise((resolve, reject) => {
    api.post(`consultation/${patient.pk}/pulse/results/${patient.analyze_id}`, {})
      .then(
        (response: any) => {
          resolve(response);
        },
        (response: any) => {
          reject(response);
        }
      )
      .catch((error: any) => {
        reject(error);
      });
  });
}

const user = {
  getPatientsList,
  getPatient,
  sendModifiedPatientData,
  sendNewPatientData,
  findPatient,
  findPatientData,
  getPatientReceptions,
  sendPatientConsultation,
  getAutomaticGraphicKey,
  sendModifiedPatientConsultation,
  sendNewPatientConsultation,
  getPatientAnalyzes,
  sendPatientAnalyze
};
export default user;