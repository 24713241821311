import './index.scss';
import React, {useEffect, useState} from 'react';
import {useLanguageDictionary} from '../../../utilities';
import {useDispatch, useSelector} from 'react-redux';
import {TStorageProps} from '../../../types';
import {DateTime} from 'luxon';
import Input from '../../ui/input';
import Select from '../../ui/select';
import {useFormik} from 'formik';
import user from '../../../api/requests/patients';
import {setModalParameters, setPatientLastReception, setPatientReceptions} from '../../../storage/actions';

const NewReportComponent: React.FC = () => {
  const {patientsListItem} = useSelector((storage: TStorageProps) => ({patientsListItem: storage.patients.patientsListItem}));
  const {patientLastReception} = useSelector((storage: TStorageProps) => ({patientLastReception: storage.patients.patientLastReception}));
  const {modalOptions} = useSelector((storage: TStorageProps) => ({modalOptions: storage.common.modal.modalOptions}));
  const {dictionary} = useLanguageDictionary();
  const dispatch = useDispatch();
  const {doctorsFullName, contacts} = patientsListItem;
  const [isManualMode, setIsManualMode] = useState<string>('true');
  const [smallIntestineState, setSmallIntestineState] = useState<number | string>('');
  const [heartState, setHeartState] = useState<number | string>('');
  const [gallbladderState, setGallbladderState] = useState<number | string>('');
  const [liverState, setLiverState] = useState<number | string>('');
  const [bladderState, setBladderState] = useState<number | string>('');
  const [leftKidneyState, setLeftKidneyState] = useState<number | string>('');
  const [colonState, setColonState] = useState<number | string>('');
  const [lungsState, setLungsState] = useState<number | string>('');
  const [stomachState, setStomachState] = useState<number | string>('');
  const [pancreasSpleenState, setPancreasSpleenState] = useState<number | string>('');
  const [capillaryState, setCapillaryState] = useState<number | string>('');
  const [rightKidneyState, setRightKidneyState] = useState<number | string>('');
  const [smallIntestineMemoryState, setSmallIntestineMemoryState] = useState<number | string>('');
  const [heartMemoryState, setHeartMemoryState] = useState<number | string>('');
  const [gallbladderMemoryState, setGallbladderMemoryState] = useState<number | string>('');
  const [liverMemoryState, setLiverMemoryState] = useState<number | string>('');
  const [bladderMemoryState, setBladderMemoryState] = useState<number | string>('');
  const [leftKidneyMemoryState, setLeftKidneyMemoryState] = useState<number | string>('');
  const [colonMemoryState, setColonMemoryState] = useState<number | string>('');
  const [lungsMemoryState, setLungsMemoryState] = useState<number | string>('');
  const [stomachMemoryState, setStomachMemoryState] = useState<number | string>('');
  const [pancreasSpleenMemoryState, setPancreasSpleenMemoryState] = useState<number | string>('');
  const [capillaryMemoryState, setCapillaryMemoryState] = useState<number | string>('');
  const [rightKidneyMemoryState, setRightKidneyMemoryState] = useState<number | string>('');
  const [complaints, setComplaints] = useState<string>('');
  const [anamnesis, setAnamnesis] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [pulseDesc, setPulseDesc] = useState<string>('');
  const [tongueDesc, setTongueDesc] = useState<string>('');
  const [diagnosis, setDiagnosis] = useState<string>('');

  useEffect(() => {
    if (patientLastReception) {
      setComplaints(patientLastReception.complaints ? patientLastReception.complaints : '');
      setAnamnesis(patientLastReception.anamnesis ? patientLastReception.anamnesis : '');
      setNotes(patientLastReception.notes ? patientLastReception.notes : '');
      setPulseDesc(patientLastReception.pulse_desc ? patientLastReception.pulse_desc : '');
      setTongueDesc(patientLastReception.tongue_desc ? patientLastReception.tongue_desc : '');
      setDiagnosis(patientLastReception.diagnosis ? patientLastReception.diagnosis : '');
      if (patientLastReception.manual_calc) {
        for (let i = 0; i < patientLastReception.manual_calc.length; i++) {
          if (patientLastReception.manual_calc[i].name === 'small intestine') {
            setSmallIntestineState(patientLastReception.manual_calc[i].state);
            setSmallIntestineMemoryState(patientLastReception.manual_calc[i].state);
          }
          if (patientLastReception.manual_calc[i].name === 'heart') {
            setHeartState(patientLastReception.manual_calc[i].state);
            setHeartMemoryState(patientLastReception.manual_calc[i].state);
          }
          if (patientLastReception.manual_calc[i].name === 'gallbladder') {
            setGallbladderState(patientLastReception.manual_calc[i].state);
            setGallbladderMemoryState(patientLastReception.manual_calc[i].state);
          }
          if (patientLastReception.manual_calc[i].name === 'liver') {
            setLiverState(patientLastReception.manual_calc[i].state);
            setLiverMemoryState(patientLastReception.manual_calc[i].state);
          }
          if (patientLastReception.manual_calc[i].name === 'bladder') {
            setBladderState(patientLastReception.manual_calc[i].state);
            setBladderMemoryState(patientLastReception.manual_calc[i].state);
          }
          if (patientLastReception.manual_calc[i].name === 'left kidney') {
            setLeftKidneyState(patientLastReception.manual_calc[i].state);
            setLeftKidneyMemoryState(patientLastReception.manual_calc[i].state);
          }
          if (patientLastReception.manual_calc[i].name === 'colon') {
            setColonState(patientLastReception.manual_calc[i].state);
            setColonMemoryState(patientLastReception.manual_calc[i].state);
          }
          if (patientLastReception.manual_calc[i].name === 'lungs') {
            setLungsState(patientLastReception.manual_calc[i].state);
            setLungsMemoryState(patientLastReception.manual_calc[i].state);
          }
          if (patientLastReception.manual_calc[i].name === 'stomach') {
            setStomachState(patientLastReception.manual_calc[i].state);
            setStomachMemoryState(patientLastReception.manual_calc[i].state);
          }
          if (patientLastReception.manual_calc[i].name === 'pancreas spleen') {
            setPancreasSpleenState(patientLastReception.manual_calc[i].state);
            setPancreasSpleenMemoryState(patientLastReception.manual_calc[i].state);
          }
          if (patientLastReception.manual_calc[i].name === 'capillary') {
            setCapillaryState(patientLastReception.manual_calc[i].state);
            setCapillaryMemoryState(patientLastReception.manual_calc[i].state);
          }
          if (patientLastReception.manual_calc[i].name === 'right kidney') {
            setRightKidneyState(patientLastReception.manual_calc[i].state);
            setRightKidneyMemoryState(patientLastReception.manual_calc[i].state);
          }
        }
      }
    }
  }, [patientLastReception]);

  useEffect(() => {
    if (modalOptions) {
      if (modalOptions.organs) {
        setIsManualMode('false');
        for (let i = 0; i < modalOptions.organs.length; i++) {
          (modalOptions.organs[i].name === 'small intestine') && setSmallIntestineState(modalOptions.organs[i].state);
          (modalOptions.organs[i].name === 'heart') && setHeartState(modalOptions.organs[i].state);
          (modalOptions.organs[i].name === 'gallbladder') && setGallbladderState(modalOptions.organs[i].state);
          (modalOptions.organs[i].name === 'liver') && setLiverState(modalOptions.organs[i].state);
          (modalOptions.organs[i].name === 'bladder') && setBladderState(modalOptions.organs[i].state);
          (modalOptions.organs[i].name === 'left kidney') && setLeftKidneyState(modalOptions.organs[i].state);
          (modalOptions.organs[i].name === 'colon') && setColonState(modalOptions.organs[i].state);
          (modalOptions.organs[i].name === 'lungs') && setLungsState(modalOptions.organs[i].state);
          (modalOptions.organs[i].name === 'stomach') && setStomachState(modalOptions.organs[i].state);
          (modalOptions.organs[i].name === 'pancreas spleen') && setPancreasSpleenState(modalOptions.organs[i].state);
          (modalOptions.organs[i].name === 'capillary') && setCapillaryState(modalOptions.organs[i].state);
          (modalOptions.organs[i].name === 'right kidney') && setRightKidneyState(modalOptions.organs[i].state);
        }
      }
    }
  }, [modalOptions.organs]);

  const handlerSelectType = (value: string) => {
    setIsManualMode(value);
    if (value === 'true') {
      setSmallIntestineState(smallIntestineMemoryState);
      setHeartState(heartMemoryState);
      setGallbladderState(gallbladderMemoryState);
      setLiverState(liverMemoryState);
      setBladderState(bladderMemoryState);
      setLeftKidneyState(leftKidneyMemoryState);
      setColonState(colonMemoryState);
      setLungsState(lungsMemoryState);
      setStomachState(stomachMemoryState);
      setPancreasSpleenState(pancreasSpleenMemoryState);
      setCapillaryState(capillaryMemoryState);
      setRightKidneyState(rightKidneyMemoryState);
    } else {
      setSmallIntestineState('');
      setHeartState('');
      setGallbladderState('');
      setLiverState('');
      setBladderState('');
      setLeftKidneyState('');
      setColonState('');
      setLungsState('');
      setStomachState('');
      setPancreasSpleenState('');
      setCapillaryState('');
      setRightKidneyState('');
    }
  }

  const validate = (values: {
    small_intestine: number | string,
    heart: number | string,
    gallbladder: number | string,
    liver: number | string,
    bladder: number | string,
    left_kidney: number | string,
    colon: number | string,
    lungs: number | string,
    stomach: number | string,
    pancreas_spleen: number | string,
    capillary: number | string,
    right_kidney: number | string
  }) => {
    const errors: any = {};
    if (!values.small_intestine.toString().length) {
      errors.small_intestine = dictionary['requiredFields'];
    }
    if (!values.heart.toString().length) {
      errors.heart = dictionary['requiredFields'];
    }
    if (!values.gallbladder.toString().length) {
      errors.gallbladder = dictionary['requiredFields'];
    }
    if (!values.liver.toString().length) {
      errors.liver = dictionary['requiredFields'];
    }
    if (!values.bladder.toString().length) {
      errors.bladder = dictionary['requiredFields'];
    }
    if (!values.left_kidney.toString().length) {
      errors.left_kidney = dictionary['requiredFields'];
    }
    if (!values.colon.toString().length) {
      errors.colon = dictionary['requiredFields'];
    }
    if (!values.lungs.toString().length) {
      errors.lungs = dictionary['requiredFields'];
    }
    if (!values.stomach.toString().length) {
      errors.stomach = dictionary['requiredFields'];
    }
    if (!values.pancreas_spleen.toString().length) {
      errors.pancreas_spleen = dictionary['requiredFields'];
    }
    if (!values.capillary.toString().length) {
      errors.capillary = dictionary['requiredFields'];
    }
    if (!values.right_kidney.toString().length) {
      errors.right_kidney = dictionary['requiredFields'];
    }
    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      complaints: complaints,
      anamnesis: anamnesis,
      notes: notes,
      pulse_desc: pulseDesc,
      tongue_desc: tongueDesc,
      diagnosis: diagnosis,
      small_intestine: smallIntestineState,
      heart: heartState,
      gallbladder: gallbladderState,
      liver: liverState,
      bladder: bladderState,
      left_kidney: leftKidneyState,
      colon: colonState,
      lungs: lungsState,
      stomach: stomachState,
      pancreas_spleen: pancreasSpleenState,
      capillary: capillaryState,
      right_kidney: rightKidneyState
    },
    validate,
    onSubmit: (values: any) => {
      if (modalOptions.organs) {
        user.sendModifiedPatientConsultation({
          pk: modalOptions.pk,
          patient_id: patientsListItem.pk,
          complaints: values.complaints,
          anamnesis: values.anamnesis,
          notes: values.notes,
          pulse_desc: values.pulse_desc,
          tongue_desc: values.tongue_desc,
          diagnosis: values.diagnosis
        })
          .then(
            () => {
              user.getPatientReceptions(patientsListItem.pk)
                .then(
                  (data: any) => data.json()
                )
                .then(
                  (data: any) => {
                    dispatch(setPatientReceptions(data));
                    dispatch(setModalParameters(false));
                  }
                )
                .catch((error: any) => error);
            }
          )
          .catch((error: any) => error);
      } else {
        user.sendNewPatientConsultation({
          pk: 0,
          complaints: values.complaints,
          anamnesis: values.anamnesis,
          notes: values.notes,
          pulse_desc: values.pulse_desc,
          tongue_desc: values.tongue_desc,
          diagnosis: values.diagnosis,
          prescription: null,
          manual_calc: isManualMode === 'true' ? [
            {name: 'small intestine', state: values.small_intestine},
            {name: 'heart', state: values.heart},
            {name: 'gallbladder', state: values.gallbladder},
            {name: 'liver', state: values.liver},
            {name: 'bladder', state: values.bladder},
            {name: 'left kidney', state: values.left_kidney},
            {name: 'colon', state: values.colon},
            {name: 'lungs', state: values.lungs},
            {name: 'stomach', state: values.stomach},
            {name: 'pancreas spleen', state: values.pancreas_spleen},
            {name: 'capillary', state: values.capillary},
            {name: 'right kidney', state: values.right_kidney},
          ] : null,
          calc_results: null,
          patient_id: patientsListItem.pk
        })
          .then(
            () => {
              user.getPatientReceptions(patientsListItem.pk)
                .then(
                  (data: any) => data.json()
                )
                .then(
                  (data: any) => {
                    dispatch(setPatientReceptions(data));
                    dispatch(setPatientLastReception(undefined));
                    dispatch(setModalParameters(false));
                  }
                )
                .catch((error: any) => error);
            }
          )
          .catch((error: any) => error);
      }
    }
  });

  const optionsOrgansState = {
    "-2": "-2",
    "-1": "-1",
    "0": "0",
    "1": "1",
    "2": "2"
  };

  const optionsReportType = {
    true: dictionary['manual'],
    false: dictionary['auto']
  };

  return (
    <>
      <span className="modal__title">
        {modalOptions.organs ? dictionary['inspectionData'] : dictionary['newReport']}
      </span>
      <div className="NewReport">
        <div className="NewReport__block">
          <div className="NewReport__content">
            <form onSubmit={formik.handleSubmit}>
              <div className="NewReport__form">
                <div className="NewReport__heading">
                  <div className="NewReport__heading-point">
                    <span className="modal__label NewReport__heading-point-label">
                      {dictionary['patient']}
                    </span>
                    <div className="NewReport__heading-point-value">
                      {doctorsFullName.substring(0, doctorsFullName.indexOf('_'))} {doctorsFullName.substring(doctorsFullName.indexOf('_') + 1, doctorsFullName.lastIndexOf('_'))} {doctorsFullName.substring(doctorsFullName.length, doctorsFullName.lastIndexOf('_') + 1)}
                    </div>
                  </div>
                  <div className="NewReport__heading-point">
                    <span className="modal__label NewReport__heading-point-label">
                      {dictionary['telephone']}
                    </span>
                    <div className="NewReport__heading-point-value">
                      {contacts}
                    </div>
                  </div>
                  <div className="NewReport__heading-point">
                    <span className="modal__label NewReport__heading-point-label">
                      {dictionary['dateOfInspection']}
                    </span>
                    <div className="NewReport__heading-point-value">
                      {DateTime.fromISO(DateTime.now().toISO(), {zone: "utc"}).toLocal().toFormat('dd/MM/yyyy')} {DateTime.fromISO(DateTime.now().toISO(), {zone: "utc"}).toLocal().toFormat('HH:mm')}
                    </div>
                  </div>
                </div>
                <div className="NewReport__fields">
                  <div className="NewReport__fields-point">
                    <span className="modal__label NewReport__fields-point-label">
                      {dictionary['mainComplaints']}
                    </span>
                    <textarea
                      className="textarea textarea_primary NewReport__fields-point-textarea"
                      placeholder={dictionary['mainComplaints']}
                      value={formik.values.complaints}
                      onChange={(e: any) => setComplaints(e.target.value)}
                    />
                  </div>
                  <div className="NewReport__fields-point">
                    <span className="modal__label NewReport__fields-point-label">
                      {dictionary['anamnesis']}
                    </span>
                    <textarea
                      className="textarea textarea_primary NewReport__fields-point-textarea"
                      placeholder={dictionary['anamnesis']}
                      value={formik.values.anamnesis}
                      onChange={(e: any) => setAnamnesis(e.target.value)}
                    />
                  </div>
                  <div className="NewReport__fields-point">
                    <span className="modal__label NewReport__fields-point-label">
                      {dictionary['inspectionData']}
                    </span>
                    <textarea
                      className="textarea textarea_primary NewReport__fields-point-textarea"
                      placeholder={dictionary['inspectionData']}
                      value={formik.values.notes}
                      onChange={(e: any) => setNotes(e.target.value)}
                    />
                  </div>
                  <div className="NewReport__fields-point">
                    <span className="modal__label NewReport__fields-point-label">
                      {dictionary['pulseType']}
                    </span>
                    <textarea
                      className="textarea textarea_primary NewReport__fields-point-textarea"
                      placeholder={dictionary['pulseType']}
                      value={formik.values.pulse_desc}
                      onChange={(e: any) => setPulseDesc(e.target.value)}
                    />
                  </div>
                  <div className="NewReport__fields-point">
                    <span className="modal__label NewReport__fields-point-label">
                      {dictionary['tongueView']}
                    </span>
                    <textarea
                      className="textarea textarea_primary NewReport__fields-point-textarea"
                      placeholder={dictionary['tongueView']}
                      value={formik.values.tongue_desc}
                      onChange={(e: any) => setTongueDesc(e.target.value)}
                    />
                  </div>
                  <div className="NewReport__fields-point">
                    <span className="modal__label NewReport__fields-point-label">
                      {dictionary['diagnosisAndAppointments']}
                    </span>
                    <textarea
                      className="textarea textarea_primary NewReport__fields-point-textarea"
                      placeholder={dictionary['diagnosisAndAppointments']}
                      value={formik.values.diagnosis}
                      onChange={(e: any) => setDiagnosis(e.target.value)}
                    />
                  </div>
                  <div className="NewReport__fields-point">
                    <span className="modal__label NewReport__fields-point-label">
                      {dictionary['organsCondition']}
                    </span>
                    <div className="NewReport__fields-point-area">
                      <div className="NewReport__fields-point-column">
                        <span className="NewReport__fields-point-column-title">
                          {dictionary['organsOfTheLeftHand']}
                        </span>
                        <div className="NewReport__fields-point-column-box">
                          <div className="NewReport__fields-point-column-line">
                            <span className="modal__sublabel NewReport__fields-point-column-sublabel">
                              {dictionary['colonTitle_12']}
                            </span>
                            <div className="NewReport__fields-point-column-value">
                              {isManualMode === 'true' ? (
                                <Select
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.colon}
                                  error={formik.errors.colon}
                                  showError={true}
                                  handlerOnChange={(eValue: string) => {
                                    setColonState(Number(eValue));
                                    setColonMemoryState(Number(eValue));
                                  }}
                                  options={optionsOrgansState}
                                />
                              ) : (
                                <Input
                                  type="text"
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.colon}
                                  error={formik.errors.colon}
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                          <div className="NewReport__fields-point-column-line">
                            <span className="modal__sublabel NewReport__fields-point-column-sublabel">
                              {dictionary['lungsTitle_12']}
                            </span>
                            <div className="NewReport__fields-point-column-value">
                              {isManualMode === 'true' ? (
                                <Select
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.lungs}
                                  error={formik.errors.lungs}
                                  showError={true}
                                  handlerOnChange={(eValue: string) => {
                                    setLungsState(Number(eValue));
                                    setLungsMemoryState(Number(eValue));
                                  }}
                                  options={optionsOrgansState}
                                />
                              ) : (
                                <Input
                                  type="text"
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.lungs}
                                  error={formik.errors.lungs}
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                          <div className="NewReport__fields-point-column-line">
                            <span className="modal__sublabel NewReport__fields-point-column-sublabel">
                              {dictionary['gallbladderTitle_12']}
                            </span>
                            <div className="NewReport__fields-point-column-value">
                              {isManualMode === 'true' ? (
                                <Select
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.gallbladder}
                                  error={formik.errors.gallbladder}
                                  showError={true}
                                  handlerOnChange={(eValue: string) => {
                                    setGallbladderState(Number(eValue));
                                    setGallbladderMemoryState(Number(eValue));
                                  }}
                                  options={optionsOrgansState}
                                />
                              ) : (
                                <Input
                                  type="text"
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.gallbladder}
                                  error={formik.errors.gallbladder}
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                          <div className="NewReport__fields-point-column-line">
                            <span className="modal__sublabel NewReport__fields-point-column-sublabel">
                              {dictionary['liverTitle_12']}
                            </span>
                            <div className="NewReport__fields-point-column-value">
                              {isManualMode === 'true' ? (
                                <Select
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.liver}
                                  error={formik.errors.liver}
                                  showError={true}
                                  handlerOnChange={(eValue: string) => {
                                    setLiverState(Number(eValue));
                                    setLiverMemoryState(Number(eValue));
                                  }}
                                  options={optionsOrgansState}
                                />
                              ) : (
                                <Input
                                  type="text"
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.liver}
                                  error={formik.errors.liver}
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                          <div className="NewReport__fields-point-column-line">
                            <span className="modal__sublabel NewReport__fields-point-column-sublabel">
                              {dictionary['bladderTitle_12']}
                            </span>
                            <div className="NewReport__fields-point-column-value">
                              {isManualMode === 'true' ? (
                                <Select
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.bladder}
                                  error={formik.errors.bladder}
                                  showError={true}
                                  handlerOnChange={(eValue: string) => {
                                    setBladderState(Number(eValue));
                                    setBladderMemoryState(Number(eValue));
                                  }}
                                  options={optionsOrgansState}
                                />
                              ) : (
                                <Input
                                  type="text"
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.bladder}
                                  error={formik.errors.bladder}
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                          <div className="NewReport__fields-point-column-line">
                            <span className="modal__sublabel NewReport__fields-point-column-sublabel">
                              {dictionary['leftKidneyTitle_12']}
                            </span>
                            <div className="NewReport__fields-point-column-value">
                              {isManualMode === 'true' ? (
                                <Select
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.left_kidney}
                                  error={formik.errors.left_kidney}
                                  showError={true}
                                  handlerOnChange={(eValue: string) => {
                                    setLeftKidneyState(Number(eValue));
                                    setLeftKidneyMemoryState(Number(eValue));
                                  }}
                                  options={optionsOrgansState}
                                />
                              ) : (
                                <Input
                                  type="text"
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.left_kidney}
                                  error={formik.errors.left_kidney}
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="NewReport__fields-point-column">
                        <span className="NewReport__fields-point-column-title">
                          {dictionary['organsOfTheRightHand']}
                        </span>
                        <div className="NewReport__fields-point-column-box">
                          <div className="NewReport__fields-point-column-line">
                            <span className="modal__sublabel NewReport__fields-point-column-sublabel">
                              {dictionary['smallIntestineTitle_12']}
                            </span>
                            <div className="NewReport__fields-point-column-value">
                              {isManualMode === 'true' ? (
                                <Select
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.small_intestine}
                                  error={formik.errors.small_intestine}
                                  showError={true}
                                  handlerOnChange={(eValue: string) => {
                                    setSmallIntestineState(Number(eValue));
                                    setSmallIntestineMemoryState(Number(eValue));
                                  }}
                                  options={optionsOrgansState}
                                />
                              ) : (
                                <Input
                                  type="text"
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.small_intestine}
                                  error={formik.errors.small_intestine}
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                          <div className="NewReport__fields-point-column-line">
                            <span className="modal__sublabel NewReport__fields-point-column-sublabel">
                              {dictionary['heartTitle_12']}
                            </span>
                            <div className="NewReport__fields-point-column-value">
                              {isManualMode === 'true' ? (
                                <Select
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.heart}
                                  error={formik.errors.heart}
                                  showError={true}
                                  handlerOnChange={(eValue: string) => {
                                    setHeartState(Number(eValue));
                                    setHeartMemoryState(Number(eValue));
                                  }}
                                  options={optionsOrgansState}
                                />
                              ) : (
                                <Input
                                  type="text"
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.heart}
                                  error={formik.errors.heart}
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                          <div className="NewReport__fields-point-column-line">
                            <span className="modal__sublabel NewReport__fields-point-column-sublabel">
                              {dictionary['stomachTitle_12']}
                            </span>
                            <div className="NewReport__fields-point-column-value">
                              {isManualMode === 'true' ? (
                                <Select
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.stomach}
                                  error={formik.errors.stomach}
                                  showError={true}
                                  handlerOnChange={(eValue: string) => {
                                    setStomachState(Number(eValue));
                                    setStomachMemoryState(Number(eValue));
                                  }}
                                  options={optionsOrgansState}
                                />
                              ) : (
                                <Input
                                  type="text"
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.stomach}
                                  error={formik.errors.stomach}
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                          <div className="NewReport__fields-point-column-line">
                            <span className="modal__sublabel NewReport__fields-point-column-sublabel">
                              {dictionary['pancreasSpleenTitle_12']}
                            </span>
                            <div className="NewReport__fields-point-column-value">
                              {isManualMode === 'true' ? (
                                <Select
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.pancreas_spleen}
                                  error={formik.errors.pancreas_spleen}
                                  showError={true}
                                  handlerOnChange={(eValue: string) => {
                                    setPancreasSpleenState(Number(eValue));
                                    setPancreasSpleenMemoryState(Number(eValue));
                                  }}
                                  options={optionsOrgansState}
                                />
                              ) : (
                                <Input
                                  type="text"
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.pancreas_spleen}
                                  error={formik.errors.pancreas_spleen}
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                          <div className="NewReport__fields-point-column-line">
                            <span className="modal__sublabel NewReport__fields-point-column-sublabel">
                              {dictionary['capillaryTitle_12']}
                            </span>
                            <div className="NewReport__fields-point-column-value">
                              {isManualMode === 'true' ? (
                                <Select
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.capillary}
                                  error={formik.errors.capillary}
                                  showError={true}
                                  handlerOnChange={(eValue: string) => {
                                    setCapillaryState(Number(eValue));
                                    setCapillaryMemoryState(Number(eValue));
                                  }}
                                  options={optionsOrgansState}
                                />
                              ) : (
                                <Input
                                  type="text"
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.capillary}
                                  error={formik.errors.capillary}
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                          <div className="NewReport__fields-point-column-line">
                            <span className="modal__sublabel NewReport__fields-point-column-sublabel">
                              {dictionary['rightKidneyTitle_12']}
                            </span>
                            <div className="NewReport__fields-point-column-value">
                              {isManualMode === 'true' ? (
                                <Select
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.right_kidney}
                                  error={formik.errors.right_kidney}
                                  showError={true}
                                  handlerOnChange={(eValue: string) => {
                                    setRightKidneyState(Number(eValue));
                                    setRightKidneyMemoryState(Number(eValue));
                                  }}
                                  options={optionsOrgansState}
                                />
                              ) : (
                                <Input
                                  type="text"
                                  view="primary"
                                  calledFrom="NewReport__fields-point-column"
                                  value={formik.values.right_kidney}
                                  error={formik.errors.right_kidney}
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="NewReport__info">
                  <div className="NewReport__info-point">
                    <span className="modal__label NewReport__info-point-label">
                      {dictionary['measurementType']}
                    </span>
                    <span className="NewReport__info-point-value">
                      {modalOptions.organs ? (
                        <>
                          {dictionary['auto']}
                        </>
                      ) : (
                        <Select
                          view="primary"
                          calledFrom="NewReport__info-point"
                          value={isManualMode}
                          handlerOnChange={(eValue: string) => handlerSelectType(eValue)}
                          options={optionsReportType}
                        />
                      )}
                    </span>
                    {!modalOptions.organs && (
                      isManualMode === 'false' && (
                        <button
                          className="btn btn_default NewReport__info-point-download"
                          type="button"
                          disabled
                        >
                          {dictionary['download']}
                        </button>
                      )
                    )}
                  </div>
                  {isManualMode === 'false' && (
                    <>
                      <div className="NewReport__info-point">
                        <span className="modal__label NewReport__info-point-label">
                          {dictionary['label']}
                        </span>
                        <span className="NewReport__info-point-value">
                          {dictionary[`${modalOptions.organs && modalOptions.description.mark}`]}
                        </span>
                      </div>
                      <div className="NewReport__info-point">
                        <span className="modal__label NewReport__info-point-label">
                          {dictionary['device']}
                        </span>
                        <span className="NewReport__info-point-value">
                          {modalOptions.organs && `${modalOptions.device_info.manufacturer} ${modalOptions.device_info.model}`}
                        </span>
                      </div>
                      {/*<div className="NewReport__info-point">*/}
                      {/*  <span className="modal__label NewReport__info-point-label">*/}
                      {/*    {dictionary['softwareVersion']}*/}
                      {/*  </span>*/}
                      {/*  <span className="NewReport__info-point-value">*/}
                      {/*  </span>*/}
                      {/*</div>*/}
                      {/*<div className="NewReport__info-point">*/}
                      {/*  <span className="modal__label NewReport__info-point-label">*/}
                      {/*    {dictionary['OSVersion']}*/}
                      {/*  </span>*/}
                      {/*  <span className="NewReport__info-point-value">*/}
                      {/*  </span>*/}
                      {/*</div>*/}
                    </>
                  )}
                </div>
                <button
                  className="btn btn_primary NewReport__save"
                  type="submit"
                >
                  {dictionary[`${modalOptions.organs ? 'save' : 'create'}`]}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewReportComponent;
