import React from 'react';
import './index.scss';
import Aside from './../../../components/regions/aside';
import Router from '../../../router';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {TStorageProps} from '../../../types';

const LayerWrapper: React.FC = () => {
  const {isUserAuthorized} = useSelector((storage: TStorageProps) => ({isUserAuthorized: storage.user.isUserAuthorized}));

  return (
    <div className={classNames('Layer', {
      'user_authorized': isUserAuthorized
    })}>
      <div className="Layer__block">
        <Aside/>
        <div className="Layer__content">
          <Router/>
        </div>
      </div>
    </div>
  );
}

export default LayerWrapper;
