import './index.scss';
import React, {useEffect, useState} from 'react';
import {useLanguageDictionary} from '../../../utilities';
import {useDispatch, useSelector} from 'react-redux';
import {TStorageProps} from '../../../types';
import {DateTime} from 'luxon';
import {useFormik} from 'formik';
import Select from '../../ui/select';
import user from '../../../api/requests/patients';
import {setModalParameters, setPatientReceptions} from '../../../storage/actions';

const InspectionDataManualComponent: React.FC = () => {
  const {modalOptions} = useSelector((storage: TStorageProps) => ({modalOptions: storage.common.modal.modalOptions}));
  const {patientsListItem} = useSelector((storage: TStorageProps) => ({patientsListItem: storage.patients.patientsListItem}));
  const {dictionary} = useLanguageDictionary();
  const dispatch = useDispatch();
  const {doctorsFullName, contacts} = patientsListItem;
  const [smallIntestineState, setSmallIntestineState] = useState<number | string>('');
  const [heartState, setHeartState] = useState<number | string>('');
  const [gallbladderState, setGallbladderState] = useState<number | string>('');
  const [liverState, setLiverState] = useState<number | string>('');
  const [bladderState, setBladderState] = useState<number | string>('');
  const [leftKidneyState, setLeftKidneyState] = useState<number | string>('');
  const [colonState, setColonState] = useState<number | string>('');
  const [lungsState, setLungsState] = useState<number | string>('');
  const [stomachState, setStomachState] = useState<number | string>('');
  const [pancreasSpleenState, setPancreasSpleenState] = useState<number | string>('');
  const [capillaryState, setCapillaryState] = useState<number | string>('');
  const [rightKidneyState, setRightKidneyState] = useState<number | string>('');
  const [isButtonSaveVisible, setIsButtonSaveVisible] = useState<boolean>(false);
  const {
    anamnesis,
    atime,
    complaints,
    diagnosis,
    manual_calc,
    notes,
    pk,
    pulse_desc,
    tongue_desc
  } = modalOptions;

  useEffect(() => {
    if (manual_calc) {
      for (let i = 0; i < manual_calc.length; i++) {
        (manual_calc[i].name === 'small intestine') && setSmallIntestineState(manual_calc[i].state);
        (manual_calc[i].name === 'heart') && setHeartState(manual_calc[i].state);
        (manual_calc[i].name === 'gallbladder') && setGallbladderState(manual_calc[i].state);
        (manual_calc[i].name === 'liver') && setLiverState(manual_calc[i].state);
        (manual_calc[i].name === 'bladder') && setBladderState(manual_calc[i].state);
        (manual_calc[i].name === 'left kidney') && setLeftKidneyState(manual_calc[i].state);
        (manual_calc[i].name === 'colon') && setColonState(manual_calc[i].state);
        (manual_calc[i].name === 'lungs') && setLungsState(manual_calc[i].state);
        (manual_calc[i].name === 'stomach') && setStomachState(manual_calc[i].state);
        (manual_calc[i].name === 'pancreas spleen') && setPancreasSpleenState(manual_calc[i].state);
        (manual_calc[i].name === 'capillary') && setCapillaryState(manual_calc[i].state);
        (manual_calc[i].name === 'right kidney') && setRightKidneyState(manual_calc[i].state);
      }
    }
  }, [manual_calc]);

  const validate = (values: any) => {
    let transformedModalOptionsString: string = `${modalOptions.complaints} ${modalOptions.anamnesis} ${modalOptions.notes} ${modalOptions.pulse_desc} ${modalOptions.tongue_desc} ${modalOptions.diagnosis}`;
    let formikOptionsString: string = `${values.complaints} ${values.anamnesis} ${values.notes} ${values.pulse_desc} ${values.tongue_desc} ${values.diagnosis} ${values.small_intestine} ${values.heart} ${values.gallbladder} ${values.liver} ${values.bladder} ${values.left_kidney} ${values.colon} ${values.lungs} ${values.stomach} ${values.pancreas_spleen} ${values.capillary} ${values.right_kidney}`;
    manual_calc.map((organ: any) => {
      transformedModalOptionsString += ` ${organ.state}`;
    });
    if (transformedModalOptionsString === formikOptionsString) {
      setIsButtonSaveVisible(false);
    } else {
      setIsButtonSaveVisible(true);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pk,
      complaints,
      anamnesis,
      notes,
      pulse_desc,
      tongue_desc,
      diagnosis,
      small_intestine: smallIntestineState,
      heart: heartState,
      gallbladder: gallbladderState,
      liver: liverState,
      bladder: bladderState,
      left_kidney: leftKidneyState,
      colon: colonState,
      lungs: lungsState,
      stomach: stomachState,
      pancreas_spleen: pancreasSpleenState,
      capillary: capillaryState,
      right_kidney: rightKidneyState
    },
    validate,
    onSubmit: (values: any) => {
      user.sendModifiedPatientConsultation({
        pk: values.pk,
        complaints: values.complaints,
        anamnesis: values.anamnesis,
        notes: values.notes,
        pulse_desc: values.pulse_desc,
        tongue_desc: values.tongue_desc,
        diagnosis: values.diagnosis,
        manual_calc: [
          {name: 'small intestine', state: values.small_intestine},
          {name: 'heart', state: values.heart},
          {name: 'gallbladder', state: values.gallbladder},
          {name: 'liver', state: values.liver},
          {name: 'bladder', state: values.bladder},
          {name: 'left kidney', state: values.left_kidney},
          {name: 'colon', state: values.colon},
          {name: 'lungs', state: values.lungs},
          {name: 'stomach', state: values.stomach},
          {name: 'pancreas spleen', state: values.pancreas_spleen},
          {name: 'capillary', state: values.capillary},
          {name: 'right kidney', state: values.right_kidney},
        ]
      })
        .then(
          (data: any) => data.json()
        )
        .then(
          () => {
            user.getPatientReceptions(patientsListItem.pk)
              .then(
                (data: any) => data.json()
              )
              .then(
                (data: any) => {
                  dispatch(setPatientReceptions(data));
                  dispatch(setModalParameters(false));
                }
              )
              .catch((error: any) => error);
          }
        )
        .catch((error: any) => error);
    }
  });

  const optionsOrgansState = {
    "-2": "-2",
    "-1": "-1",
    "0": "0",
    "1": "1",
    "2": "2"
  };

  return (
    <>
      <span className="modal__title">
        {dictionary['inspectionData']}
      </span>
      <div className="InspectionDataManual">
        <div className="InspectionDataManual__block">
          <div className="InspectionDataManual__content">
            <form onSubmit={formik.handleSubmit}>
              <div className="InspectionDataManual__form">
                <div className="InspectionDataManual__heading">
                  <div className="InspectionDataManual__heading-point">
                    <span className="modal__label InspectionDataManual__heading-point-label">
                      {dictionary['patient']}
                    </span>
                    <div className="InspectionDataManual__heading-point-value">
                      {doctorsFullName.substring(0, doctorsFullName.indexOf('_'))} {doctorsFullName.substring(doctorsFullName.indexOf('_') + 1, doctorsFullName.lastIndexOf('_'))} {doctorsFullName.substring(doctorsFullName.length, doctorsFullName.lastIndexOf('_') + 1)}
                    </div>
                  </div>
                  <div className="InspectionDataManual__heading-point">
                    <span className="modal__label InspectionDataManual__heading-point-label">
                      {dictionary['telephone']}
                    </span>
                    <div className="InspectionDataManual__heading-point-value">
                      {contacts}
                    </div>
                  </div>
                  <div className="InspectionDataManual__heading-point">
                    <span className="modal__label InspectionDataManual__heading-point-label">
                      {dictionary['dateOfInspection']}
                    </span>
                    <div className="InspectionDataManual__heading-point-value">
                      {DateTime.fromISO(atime, {zone: "utc"}).toLocal().toFormat('dd/MM/yyyy')} {DateTime.fromISO(atime, {zone: "utc"}).toLocal().toFormat('HH:mm')}
                    </div>
                  </div>
                </div>
                <div className="InspectionDataManual__fields">
                  <div className="InspectionDataManual__fields-point">
                    <span className="modal__label InspectionDataManual__fields-point-label">
                      {dictionary['mainComplaints']}
                    </span>
                    <textarea
                      className="textarea textarea_primary InspectionDataManual__fields-point-textarea"
                      placeholder={dictionary['mainComplaints']}
                      value={formik.values.complaints}
                      onChange={(e: any) => formik.setFieldValue('complaints', e.target.value)}
                    />
                  </div>
                  <div className="InspectionDataManual__fields-point">
                    <span className="modal__label InspectionDataManual__fields-point-label">
                      {dictionary['anamnesis']}
                    </span>
                    <textarea
                      className="textarea textarea_primary InspectionDataManual__fields-point-textarea"
                      placeholder={dictionary['anamnesis']}
                      value={formik.values.anamnesis}
                      onChange={(e: any) => formik.setFieldValue('anamnesis', e.target.value)}
                    />
                  </div>
                  <div className="InspectionDataManual__fields-point">
                    <span className="modal__label InspectionDataManual__fields-point-label">
                      {dictionary['inspectionData']}
                    </span>
                    <textarea
                      className="textarea textarea_primary InspectionDataManual__fields-point-textarea"
                      placeholder={dictionary['inspectionData']}
                      value={formik.values.notes}
                      onChange={(e: any) => formik.setFieldValue('notes', e.target.value)}
                    />
                  </div>
                  <div className="InspectionDataManual__fields-point">
                    <span className="modal__label InspectionDataManual__fields-point-label">
                      {dictionary['pulseType']}
                    </span>
                    <textarea
                      className="textarea textarea_primary InspectionDataManual__fields-point-textarea"
                      placeholder={dictionary['pulseType']}
                      value={formik.values.pulse_desc}
                      onChange={(e: any) => formik.setFieldValue('pulse_desc', e.target.value)}
                    />
                  </div>
                  <div className="InspectionDataManual__fields-point">
                    <span className="modal__label InspectionDataManual__fields-point-label">
                      {dictionary['tongueView']}
                    </span>
                    <textarea
                      className="textarea textarea_primary InspectionDataManual__fields-point-textarea"
                      placeholder={dictionary['tongueView']}
                      value={formik.values.tongue_desc}
                      onChange={(e: any) => formik.setFieldValue('tongue_desc', e.target.value)}
                    />
                  </div>
                  <div className="InspectionDataManual__fields-point">
                    <span className="modal__label InspectionDataManual__fields-point-label">
                      {dictionary['diagnosisAndAppointments']}
                    </span>
                    <textarea
                      className="textarea textarea_primary InspectionDataManual__fields-point-textarea"
                      placeholder={dictionary['diagnosisAndAppointments']}
                      value={formik.values.diagnosis}
                      onChange={(e: any) => formik.setFieldValue('diagnosis', e.target.value)}
                    />
                  </div>
                  <div className="InspectionDataManual__fields-point">
                    <span className="modal__label InspectionDataManual__fields-point-label">
                      {dictionary['organsCondition']}
                    </span>
                    <div className="InspectionDataManual__fields-point-area">
                      <div className="InspectionDataManual__fields-point-column">
                        <span className="InspectionDataManual__fields-point-column-title">
                          {dictionary['organsOfTheLeftHand']}
                        </span>
                        <div className="InspectionDataManual__fields-point-column-box">
                          <div className="InspectionDataManual__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataManual__fields-point-column-sublabel">
                              {dictionary['colonTitle_12']}
                            </span>
                            <div className="InspectionDataManual__fields-point-column-value">
                              <Select
                                view="primary"
                                calledFrom="InspectionDataManual__fields-point-column"
                                value={formik.values.colon}
                                handlerOnChange={(eValue: string) => formik.setFieldValue('colon', Number(eValue))}
                                options={optionsOrgansState}
                              />
                            </div>
                          </div>
                          <div className="InspectionDataManual__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataManual__fields-point-column-sublabel">
                              {dictionary['lungsTitle_12']}
                            </span>
                            <div className="InspectionDataManual__fields-point-column-value">
                              <Select
                                view="primary"
                                calledFrom="InspectionDataManual__fields-point-column"
                                value={formik.values.lungs}
                                handlerOnChange={(eValue: string) => formik.setFieldValue('lungs', Number(eValue))}
                                options={optionsOrgansState}
                              />
                            </div>
                          </div>
                          <div className="InspectionDataManual__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataManual__fields-point-column-sublabel">
                              {dictionary['gallbladderTitle_12']}
                            </span>
                            <div className="InspectionDataManual__fields-point-column-value">
                              <Select
                                view="primary"
                                calledFrom="InspectionDataManual__fields-point-column"
                                value={formik.values.gallbladder}
                                handlerOnChange={(eValue: string) => formik.setFieldValue('gallbladder', Number(eValue))}
                                options={optionsOrgansState}
                              />
                            </div>
                          </div>
                          <div className="InspectionDataManual__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataManual__fields-point-column-sublabel">
                              {dictionary['liverTitle_12']}
                            </span>
                            <div className="InspectionDataManual__fields-point-column-value">
                              <Select
                                view="primary"
                                calledFrom="InspectionDataManual__fields-point-column"
                                value={formik.values.liver}
                                handlerOnChange={(eValue: string) => formik.setFieldValue('liver', Number(eValue))}
                                options={optionsOrgansState}
                              />
                            </div>
                          </div>
                          <div className="InspectionDataManual__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataManual__fields-point-column-sublabel">
                              {dictionary['bladderTitle_12']}
                            </span>
                            <div className="InspectionDataManual__fields-point-column-value">
                              <Select
                                view="primary"
                                calledFrom="InspectionDataManual__fields-point-column"
                                value={formik.values.bladder}
                                handlerOnChange={(eValue: string) => formik.setFieldValue('bladder', Number(eValue))}
                                options={optionsOrgansState}
                              />
                            </div>
                          </div>
                          <div className="InspectionDataManual__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataManual__fields-point-column-sublabel">
                              {dictionary['leftKidneyTitle_12']}
                            </span>
                            <div className="InspectionDataManual__fields-point-column-value">
                              <Select
                                view="primary"
                                calledFrom="InspectionDataManual__fields-point-column"
                                value={formik.values.left_kidney}
                                handlerOnChange={(eValue: string) => formik.setFieldValue('left_kidney', Number(eValue))}
                                options={optionsOrgansState}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="InspectionDataManual__fields-point-column">
                        <span className="InspectionDataManual__fields-point-column-title">
                          {dictionary['organsOfTheRightHand']}
                        </span>
                        <div className="InspectionDataManual__fields-point-column-box">
                          <div className="InspectionDataManual__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataManual__fields-point-column-sublabel">
                              {dictionary['smallIntestineTitle_12']}
                            </span>
                            <div className="InspectionDataManual__fields-point-column-value">
                              <Select
                                view="primary"
                                calledFrom="InspectionDataManual__fields-point-column"
                                value={formik.values.small_intestine}
                                handlerOnChange={(eValue: string) => formik.setFieldValue('small_intestine', Number(eValue))}
                                options={optionsOrgansState}
                              />
                            </div>
                          </div>
                          <div className="InspectionDataManual__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataManual__fields-point-column-sublabel">
                              {dictionary['heartTitle_12']}
                            </span>
                            <div className="InspectionDataManual__fields-point-column-value">
                              <Select
                                view="primary"
                                calledFrom="InspectionDataManual__fields-point-column"
                                value={formik.values.heart}
                                handlerOnChange={(eValue: string) => formik.setFieldValue('heart', Number(eValue))}
                                options={optionsOrgansState}
                              />
                            </div>
                          </div>
                          <div className="InspectionDataManual__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataManual__fields-point-column-sublabel">
                              {dictionary['stomachTitle_12']}
                            </span>
                            <div className="InspectionDataManual__fields-point-column-value">
                              <Select
                                view="primary"
                                calledFrom="InspectionDataManual__fields-point-column"
                                value={formik.values.stomach}
                                handlerOnChange={(eValue: string) => formik.setFieldValue('stomach', Number(eValue))}
                                options={optionsOrgansState}
                              />
                            </div>
                          </div>
                          <div className="InspectionDataManual__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataManual__fields-point-column-sublabel">
                              {dictionary['pancreasSpleenTitle_12']}
                            </span>
                            <div className="InspectionDataManual__fields-point-column-value">
                              <Select
                                view="primary"
                                calledFrom="InspectionDataManual__fields-point-column"
                                value={formik.values.pancreas_spleen}
                                handlerOnChange={(eValue: string) => formik.setFieldValue('pancreas_spleen', Number(eValue))}
                                options={optionsOrgansState}
                              />
                            </div>
                          </div>
                          <div className="InspectionDataManual__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataManual__fields-point-column-sublabel">
                              {dictionary['capillaryTitle_12']}
                            </span>
                            <div className="InspectionDataManual__fields-point-column-value">
                              <Select
                                view="primary"
                                calledFrom="InspectionDataManual__fields-point-column"
                                value={formik.values.capillary}
                                handlerOnChange={(eValue: string) => formik.setFieldValue('capillary', Number(eValue))}
                                options={optionsOrgansState}
                              />
                            </div>
                          </div>
                          <div className="InspectionDataManual__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataManual__fields-point-column-sublabel">
                              {dictionary['rightKidneyTitle_12']}
                            </span>
                            <div className="InspectionDataManual__fields-point-column-value">
                              <Select
                                view="primary"
                                calledFrom="InspectionDataManual__fields-point-column"
                                value={formik.values.right_kidney}
                                handlerOnChange={(eValue: string) => formik.setFieldValue('right_kidney', Number(eValue))}
                                options={optionsOrgansState}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="InspectionDataManual__info">
                  <div className="InspectionDataManual__info-point">
                  <span className="modal__label InspectionDataManual__info-point-label">
                    {dictionary['measurementType']}
                  </span>
                    <span className="InspectionDataManual__info-point-value">
                    {dictionary['manual']}
                  </span>
                  </div>
                </div>
                {isButtonSaveVisible && (
                  <button
                    className="btn btn_primary InspectionDataManual__save"
                    type="submit"
                  >
                    {dictionary['save']}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default InspectionDataManualComponent;
