class API {
  async invoke({method, name, options}: any) {
    return new Promise(async (resolve, reject) => {
      const newOptionsArray = [];
      const init: any = {
        method,
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
      };
      const userBackendDomain = name.includes('users/search?login=') ? 'stageauth.omnio.site' : localStorage.getItem('userBackendDomain') ? localStorage.getItem('userBackendDomain') : 'stageauth.omnio.site';
      let url = `https://${userBackendDomain}/${name}`;
      for (const key in options) {
        newOptionsArray.push(`${key}=${encodeURIComponent(options[key])}`);
      }
      if (method === 'GET') {
        if (newOptionsArray.length > 0) {
          url += `?${newOptionsArray.join('&')}`;
        }
      } else {
        init.body = JSON.stringify(options);
      }
      await fetch(url, init)
        .then(data => {
          switch (data.status) {
            case 200: {
              resolve(data);
              break;
            }
            default: {
              reject(data);
              break;
            }
          }
        })
        .catch(error => reject(error));
    });
  }
}

const _api = new API();

const get = (name: string, options: any = {}) => {
  return _api.invoke({method: 'GET', name, options});
}

const post = (name: string, options: any = {}) => {
  return _api.invoke({method: 'POST', name, options});
}

const put = (name: string, options: any = {}) => {
  return _api.invoke({method: 'PUT', name, options});
}

const patch = (name: string, options: any = {}) => {
  return _api.invoke({method: 'PATCH', name, options});
}

const del = (name: string, options: any = {}) => {
  return _api.invoke({method: 'DELETE', name, options});
}

const api = {get, post, put, patch, delete: del};
export default api;