import './index.scss';
import React from 'react';
import {useLanguageDictionary} from '../../../utilities';
import SVGInline from 'react-svg-inline';
import icons from '../../../assets/images/icons/icons.json';
import {setModalParameters} from '../../../storage/actions';
import {useDispatch} from 'react-redux';
import {TFilteredPatientReceptionsComponentProps} from '../../../types';
import Loader from '../loader';
import {DateTime} from 'luxon';

const PatientReceptionsComponent: React.FC<TFilteredPatientReceptionsComponentProps> = ({filteredPatientReceptions}) => {
  const {dictionary} = useLanguageDictionary();
  const dispatch = useDispatch();

  return (
    <div className="PatientReceptions">
      <div className="PatientReceptions__block">
        <div className="PatientReceptions__content">
          <div className="PatientReceptions__table">
            <div className="PatientReceptions__table-thead">
                <span className="PatientReceptions__table-th PatientReceptions__table-column">
                  {dictionary['dateOfInspection']}
                </span>
                <span className="PatientReceptions__table-th PatientReceptions__table-column">
                  {dictionary['analysisType']}
                </span>
                <span className="PatientReceptions__table-th PatientReceptions__table-column">
                  {dictionary['mainComplaints']}
                </span>
                <span className="PatientReceptions__table-th PatientReceptions__table-column">
                  {dictionary['action']}
                </span>
            </div>
          </div>
          <div className="PatientReceptions__table">
            <div className="PatientReceptions__table-tbody">
              {filteredPatientReceptions ? (
                <ul className="PatientReceptions__table-list">
                  {filteredPatientReceptions.map((item: any) => (
                    (item.prescription !== "0") && (
                      <li
                        key={`PatientReceptions__table-list-item_${item.pk}`}
                        className="PatientReceptions__table-list-item"
                      >
                        <span
                          className="PatientReceptions__table-td PatientReceptions__table-column">
                          {DateTime.fromISO(item.atime, {zone: "utc"}).toLocal().toFormat('dd/MM/yyyy')} {DateTime.fromISO(item.atime, {zone: "utc"}).toLocal().toFormat('HH:mm')}
                        </span>
                        <span className="PatientReceptions__table-td PatientReceptions__table-column">
                          {item.manual_calc === null ? dictionary['auto'] : dictionary['manual']}
                        </span>
                        <span
                          className="PatientReceptions__table-td PatientReceptions__table-column">
                          {item.complaints}
                        </span>
                        <span className="PatientReceptions__table-td PatientReceptions__table-column">
                          <button
                            className="PatientReceptions__table-show-inspection"
                            type="button"
                            onClick={() => dispatch(setModalParameters(true, item.manual_calc === null ? 'inspectionDataAuto' : 'inspectionDataManual', item))}
                          >
                            <SVGInline
                              className="SVGInline_stroke"
                              svg={`${icons['file_text']}`}
                            />
                          </button>
                        </span>
                      </li>
                    )
                  ))}
                </ul>
              ) : (
                <Loader/>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientReceptionsComponent;
