import './assets/styles/index.scss';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import LayerWrapper from './pages/wrappers/layer';
import {setIsMobileBrowser, setIsScreenResolution, setLanguageDictionary} from './storage/actions';
import Loader from './components/common/loader';
import {TAppProps, TStorageProps} from './types';
import {withRouter} from 'react-router-dom';
import Modal from './components/ui/modal';

const App: React.FC<TAppProps> = ({history}) => {
  const {isAppLanguageLoaded} = useSelector((storage: TStorageProps) => ({isAppLanguageLoaded: storage.common.isAppLanguageLoaded}));
  const {isUserAuthorized} = useSelector((storage: TStorageProps) => ({isUserAuthorized: storage.user.isUserAuthorized}));
  const {isModalOpen} = useSelector((storage: TStorageProps) => ({isModalOpen: storage.common.modal.isModalOpen}));
  const dispatch = useDispatch();

  useEffect(() => {
    const isMobileBrowser = window.navigator.userAgent.match('Mobile') !== null;
    const isMobileScreenResolution = window.innerWidth < 851;
    const browserLanguage = window.navigator.language.slice(0, 2).toLowerCase();
    const selectedUserLanguage = localStorage.getItem('selectedUserLanguage');
    dispatch(setIsMobileBrowser(isMobileBrowser));
    dispatch(setIsScreenResolution(isMobileScreenResolution));
    if (selectedUserLanguage && selectedUserLanguage!.length) {
      dispatch(setLanguageDictionary(selectedUserLanguage));
    } else {
      if (browserLanguage.length) {
        if (browserLanguage !== 'ru' && browserLanguage !== 'en') {
          dispatch(setLanguageDictionary('en'));
        } else {
          dispatch(setLanguageDictionary(browserLanguage));
        }
      } else {
        dispatch(setLanguageDictionary('en'));
      }
    }
    isUserAuthorized ? history.push('/') : history.push('/sign_in');
  }, [dispatch, isUserAuthorized, history]);

  useEffect(() => {
    localStorage.removeItem('userBackendDomain');
  }, [])

  return (
    <>
      {!isAppLanguageLoaded ? (
        <Loader isFixed={true}/>
      ) : (
        <>
          <LayerWrapper/>
          {isModalOpen && (
            <Modal/>
          )}
        </>
      )}
    </>
  );
}

export default withRouter(App);
