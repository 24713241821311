import React, {ChangeEvent} from 'react';
import {TSelectComponentProps} from '../../../types';

const SelectComponent: React.FC<TSelectComponentProps> = (props) => {
  const {labelClass = '', view, calledFrom, value, error, showError, handlerOnChange, options} = props;
  const optionsEntries = Object.entries(options);

  return (
    <label className={`label ${labelClass}`}>
      <select
        className={`select select_${view} ${calledFrom ? `${calledFrom}-select` : ''} ${error ? 'select_error' : ''}`}
        value={value}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          handlerOnChange(e.target.value)
        }}
      >
        <option value="" hidden/>
        {optionsEntries.map((entry, index) => (
          <option
            key={index}
            value={entry[0]}
          >
            {`${entry[1]}`}
          </option>
        ))}
      </select>
      {error && showError && (
        <span className="select__error-message">
          {error}
        </span>
      )}
    </label>
  );
}

export default SelectComponent;
