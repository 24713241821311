import React, {useRef} from 'react';
import SVGInline from 'react-svg-inline';
import icons from '../../../assets/images/icons/icons.json';
import {useDispatch, useSelector} from 'react-redux';
import {TStorageProps} from '../../../types';
import {switchMatch} from '../../../utilities';
import AddingPatient from '../../modals/adding_a_patient';
import PatientCard from '../../modals/patient_card';
import Notification from '../../modals/notification';
import InspectionDataAuto from '../../modals/inspection_data_auto';
import InspectionDataManual from '../../modals/inspection_data_manual';
import NewReport from '../../modals/new_report';
import AnalysisData from '../../modals/analysis_data';
import {setModalParameters, setPatientLastReception} from '../../../storage/actions';

const ModalComponent: React.FC = () => {
  const {modalType} = useSelector((storage: TStorageProps) => ({modalType: storage.common.modal.modalType}));
  const dispatch = useDispatch();
  const content = useRef(null);
  const currentModal = switchMatch(modalType, {
    'addingPatient': AddingPatient,
    'patientCard': PatientCard,
    'notification': Notification,
    'inspectionDataAuto': InspectionDataAuto,
    'inspectionDataManual': InspectionDataManual,
    'newReport': NewReport,
    'analysisData': AnalysisData,
    default: null
  });

  return (
    <div
      className="modal"
      onClick={(e: any) => {
        if (!e.nativeEvent.path.includes(content.current)) {
          dispatch(setModalParameters(false));
          dispatch(setPatientLastReception(undefined));
        }
      }}
    >
      <div className="modal__block">
        <div
          className="modal__content"
          ref={content}
        >
          <button
            className="modal__close"
            type="button"
            onClick={() => {
              dispatch(setModalParameters(false));
              dispatch(setPatientLastReception(undefined));
            }}
          >
            <SVGInline
              className="SVGInline_fill"
              svg={`${icons['close']}`}
            />
          </button>
          {currentModal}
        </div>
      </div>
    </div>
  )
}

export default ModalComponent;
