import './index.scss';
import React, {useState} from 'react';
import Patients from '../../../components/common/patients';
import Patient from '../../../components/common/patient';

const DesktopPage: React.FC = () => {
  const [showPatient, setShowPatient] = useState<boolean>(false);

  return (
    <div className="Desktop">
      <div className="Desktop__block">
        <div className="Desktop__content">
          <Patients handlerSetShowPatient={setShowPatient}/>
          <Patient showPatient={showPatient}/>
        </div>
      </div>
    </div>
  );
}

export default DesktopPage;
