import './index.scss';
import React from 'react';
import classNames from 'classnames';
import {TLoaderComponentProps} from '../../../types'

const LoaderComponent: React.FC<TLoaderComponentProps> = ({isFixed}) => {
  return (
    <div className={classNames('Loader', {
      'Loader_fixed': isFixed
    })}>
      <div className="Loader__block">
        <div className="Loader__content">
          <div className="Loader__self">
            <div className="Loader__rings">
              <div className="Loader__rings-item"/>
              <div className="Loader__rings-item"/>
              <div className="Loader__rings-item"/>
              <div className="Loader__rings-item"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoaderComponent;
