import React from 'react';
import {Route, Switch} from 'react-router-dom';
import PAGE_ROUTES from './routes';
import Desktop from '../pages/containers/desktop';
import SignIn from '../pages/containers/sign_in';
import NotFound from '../pages/containers/not_found';
import {useSelector} from 'react-redux';
import {TStorageProps} from '../types';

const Router: React.FC = () => {
  const {isUserAuthorized} = useSelector((storage: TStorageProps) => ({isUserAuthorized: storage.user.isUserAuthorized}));

  return (
    <>
      {isUserAuthorized ? (
        <Switch>
          <Route exact path={PAGE_ROUTES.DESKTOP} component={Desktop}/>
          <Route component={NotFound}/>
        </Switch>
      ) : (
        <Switch>
          <Route exact path={PAGE_ROUTES.SIGN_IN} component={SignIn}/>
          <Route component={NotFound}/>
        </Switch>
      )}
    </>
  );
}

export default Router;
