import update from 'react-addons-update';
import {TYPES} from '../../actions';

const initialStorage = {
  patientsList: undefined,
  patientsListItem: undefined,
  patient: undefined,
  patientReceptions: undefined,
  patientAnalyzes: undefined,
  patientLastReception: undefined
};

const patientsReducer = (storage = initialStorage, action: any) => {
  switch (action.type) {
    case TYPES.PATIENTS.SET_PATIENTS_LIST: {
      return update(storage, {
        patientsList: {$set: action.data}
      });
    }
    case TYPES.PATIENTS.SET_PATIENTS_LIST_ITEM: {
      return update(storage, {
        patientsListItem: {$set: action.data}
      });
    }
    case TYPES.PATIENTS.SET_PATIENT: {
      return update(storage, {
        patient: {$set: action.data}
      });
    }
    case TYPES.PATIENTS.SET_PATIENT_RECEPTIONS: {
      return update(storage, {
        patientReceptions: {$set: action.data}
      });
    }
    case TYPES.PATIENTS.SET_PATIENT_ANALYZES: {
      return update(storage, {
        patientAnalyzes: {$set: action.data}
      });
    }
    case TYPES.PATIENTS.SET_PATIENT_LAST_RECEPTIONS: {
      return update(storage, {
        patientLastReception: {$set: action.data}
      });
    }
    default:
      return storage;
  }
}

export default patientsReducer;