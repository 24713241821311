import './index.scss';
import React, {useEffect, useState} from 'react';
import {useLanguageDictionary} from '../../../utilities';
import {TPatientComponentProps, TStorageProps} from '../../../types';
import PatientReceptions from '../patient_receptions';
import PatientsAnalyzes from '../patient_analyzes';
import Switches from '../../ui/switches';
import {setModalParameters, setPatientAnalyzes, setPatientReceptions} from '../../../storage/actions';
import {useDispatch, useSelector} from 'react-redux';
import Select from '../../ui/select';
import user from '../../../api/requests/patients';

const PatientComponent: React.FC<TPatientComponentProps> = ({showPatient}) => {
  const {patientsListItem} = useSelector((storage: TStorageProps) => ({patientsListItem: storage.patients.patientsListItem}));
  const {patientReceptions} = useSelector((storage: TStorageProps) => ({patientReceptions: storage.patients.patientReceptions}));
  const {patientAnalyzes} = useSelector((storage: TStorageProps) => ({patientAnalyzes: storage.patients.patientAnalyzes}));
  const {dictionary} = useLanguageDictionary();
  const dispatch = useDispatch();
  const [isFirstBlockActive, setIsFirstBlockActive] = useState<boolean>(true);
  const [filterState, setFilterState] = useState<string>('');
  const [sampleState, setSampleState] = useState<string>('');
  const [filteredPatientReceptions, setFilteredPatientReceptions] = useState<any>(undefined);
  const [filteredPatientAnalyzes, setFilteredPatientAnalyzes] = useState<any>(undefined);
  const [filterPatientReceptionsState, setFilterPatientReceptionsState] = useState<string>('');
  const [filterPatientAnalyzesState, setFilterPatientAnalyzesState] = useState<string>('');
  const [samplePatientReceptionsState, setSamplePatientReceptionsState] = useState<string>('');
  const [samplePatientAnalyzesState, setSamplePatientAnalyzesState] = useState<string>('');

  useEffect(() => {
    setFilteredPatientReceptions(undefined);
    setFilteredPatientAnalyzes(undefined);
    setFilterState('');
    setSampleState('');
    setFilterPatientReceptionsState('');
    setFilterPatientAnalyzesState('');
    setSamplePatientReceptionsState('');
    setSamplePatientAnalyzesState('');
    if (patientsListItem) {
      user.getPatientReceptions(patientsListItem.pk)
        .then(
          (data: any) => data.json()
        )
        .then(
          (data: any) => {
            dispatch(setPatientReceptions(data));
            setFilteredPatientReceptions(data);
          }
        )
        .catch((error: any) => error);
      user.getPatientAnalyzes(patientsListItem.pk)
        .then(
          (data: any) => data.json()
        )
        .then(
          (data: any) => {
            dispatch(setPatientAnalyzes(data));
            setFilteredPatientAnalyzes(data);
          }
        )
        .catch((error: any) => error);
    }
  }, [dispatch, patientsListItem]);

  useEffect(() => {
    setFilteredPatientReceptions(patientReceptions);
  }, [patientReceptions]);

  useEffect(() => {
    if (sampleState.length) {
      if (isFirstBlockActive) {
        if (sampleState === 'all') {
          setFilteredPatientReceptions(patientReceptions);
        }
        if (sampleState === 'manual') {
          setFilteredPatientReceptions(
            patientReceptions.filter((item: any) => item.manual_calc !== null)
          );
        }
        if (sampleState === 'auto') {
          setFilteredPatientReceptions(
            patientReceptions.filter((item: any) => item.manual_calc === null)
          );
        }
      } else {
        if (filterState === 'label') {
          if (sampleState === 'ALL') {
            setFilteredPatientAnalyzes(patientAnalyzes);
          } else {
            setFilteredPatientAnalyzes(
              patientAnalyzes.filter((item: any) => item.description.mark === sampleState)
            );
          }
        }
        if (filterState === 'device') {
          if (sampleState === 'all') {
            setFilteredPatientAnalyzes(patientAnalyzes);
          } else {
            setFilteredPatientAnalyzes(
              patientAnalyzes.filter((item: any) => sampleState === `${item.device_info.manufacturer} ${item.device_info.model}`)
            );
          }
        }
      }
    }
  }, [sampleState, samplePatientReceptionsState, samplePatientAnalyzesState]);

  const handlerOnClick = () => {
    if (patientReceptions.length) {
      if (patientReceptions.length === 1 && patientReceptions[0].prescription === '0') {
        dispatch(setModalParameters(true, 'newReport', {}));
      } else {
        dispatch(setModalParameters(true, 'notification'));
      }
    } else {
      dispatch(setModalParameters(true, 'newReport'));
    }
  }

  const optionsFilterReceptions = {
    analysisType: dictionary['analysisType']
  };

  const optionsFilterAnalysisType = {
    all: dictionary['ALL'],
    manual: dictionary['manual'],
    auto: dictionary['auto']
  };

  const optionsFilterAnalyzes = {
    label: dictionary['label'],
    device: dictionary['device']
  };

  const optionsFilterAnalyzesLabel = {
    ALL: dictionary['ALL'],
    NOTHING: dictionary['NOTHING'],
    AFTER_WAKE_UP: dictionary['AFTER_WAKE_UP'],
    ONE_HOUR_AFTER_EATING: dictionary['ONE_HOUR_AFTER_EATING'],
    BEFORE_BEDTIME: dictionary['BEFORE_BEDTIME'],
    NIGHT_AWAKENING: dictionary['NIGHT_AWAKENING'],
    HIGH_FATIGUE: dictionary['HIGH_FATIGUE'],
    EMOTIONAL_RISE: dictionary['EMOTIONAL_RISE'],
    AT_WORK: dictionary['AT_WORK'],
    DURING_PHYSICAL_ACTIVITY: dictionary['DURING_PHYSICAL_ACTIVITY'],
    AFTER_DOING_SPORTS: dictionary['AFTER_DOING_SPORTS']
  };

  let device: any = {all: dictionary['ALL']};
  const optionsFilterAnalyzesDevice = () => {
    for (let i = 0; i < patientAnalyzes.length; i++) {
      device[`${patientAnalyzes[i].device_info.manufacturer} ${patientAnalyzes[i].device_info.model}`] = `${patientAnalyzes[i].device_info.manufacturer} ${patientAnalyzes[i].device_info.model}`;
    }
    return device;
  }
  const setFilterOptions = (value: string) => {
    if (value === 'analysisType') {
      return optionsFilterAnalysisType;
    }
    if (value === 'label') {
      return optionsFilterAnalyzesLabel;
    }
    if (value === 'device') {
      return optionsFilterAnalyzesDevice();
    }
    return '';
  }
  return (
    <div className="Patient">
      <div className="Patient__block">
        <div className="Patient__content">
          <span className="Patient__title">
            {dictionary[isFirstBlockActive ? 'receptions' : 'analyzes']}
          </span>
          {showPatient ? (
            <>
              <div className="Patient__switches">
                <Switches
                  leftButtonText={dictionary['receptions']}
                  rightButtonText={dictionary['analyzes']}
                  isLeftButtonActive={isFirstBlockActive}
                  isRightButtonActive={!isFirstBlockActive}
                  handlerLeftButton={() => {
                    setIsFirstBlockActive(true);
                    setFilterState(filterPatientReceptionsState);
                    setSampleState(samplePatientReceptionsState);
                  }}
                  handlerRightButton={() => {
                    setIsFirstBlockActive(false);
                    setFilterState(filterPatientAnalyzesState);
                    setSampleState(samplePatientAnalyzesState);
                  }}
                />
              </div>
              <p className="Patient__description">
                {dictionary['patient']}: {patientsListItem.doctorsFullName.substring(0, patientsListItem.doctorsFullName.indexOf('_'))} {patientsListItem.doctorsFullName.substring(patientsListItem.doctorsFullName.indexOf('_') + 1, patientsListItem.doctorsFullName.lastIndexOf('_'))} {patientsListItem.doctorsFullName.substring(patientsListItem.doctorsFullName.length, patientsListItem.doctorsFullName.lastIndexOf('_') + 1)}, {patientsListItem.contacts}
              </p>
              <div className="Patient__actions">
                <div className="Patient__search">
                  <div className="Patient__filter">
                    <span className="Patient__filter-title">
                      {dictionary['filterBy']}
                    </span>
                    <Select
                      view="primary"
                      calledFrom="Patient__filter"
                      value={filterState}
                      handlerOnChange={(eValue: string) => {
                        setFilterState(eValue);
                        isFirstBlockActive ? setFilterPatientReceptionsState(eValue) : setFilterPatientAnalyzesState(eValue);
                      }}
                      options={isFirstBlockActive ? optionsFilterReceptions : optionsFilterAnalyzes}
                    />
                  </div>
                  <div className="Patient__contains">
                    <span className="Patient__contains-title">
                      =
                    </span>
                    <Select
                      view="primary"
                      calledFrom="Patient__filter"
                      value={sampleState}
                      handlerOnChange={(eValue: string) => {
                        setSampleState(eValue);
                        isFirstBlockActive ? setSamplePatientReceptionsState(eValue) : setSamplePatientAnalyzesState(eValue);
                      }}
                      options={setFilterOptions(filterState)}
                    />
                  </div>
                </div>
                {isFirstBlockActive && (
                  <button
                    className="btn btn_primary Patient__create-report"
                    type="button"
                    onClick={() => handlerOnClick()}
                  >
                    {dictionary['createReport']}
                  </button>
                )}
              </div>
              {!showPatient && (
                <span className="Patient__subtitle">
                  {dictionary['theUserHasNotDoneAnyAnalysisYet']}
                </span>
              )}
              {isFirstBlockActive ? (
                <div className="Patient__receptions">
                  <PatientReceptions filteredPatientReceptions={filteredPatientReceptions}/>
                </div>
              ) : (
                <div className="Patient__analyzes">
                  <PatientsAnalyzes filteredPatientAnalyzes={filteredPatientAnalyzes}/>
                </div>
              )}
            </>
          ) : (
            <span className="Patient__subtitle">
              {dictionary['selectTheRequiredPatientToViewTheAppointmentHistory']}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default PatientComponent;
