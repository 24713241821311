import './index.scss';
import React from 'react';
import {useLanguageDictionary} from '../../../utilities';
import SVGInline from 'react-svg-inline';
import icons from '../../../assets/images/icons/icons.json';
import {useDispatch} from 'react-redux';
import {setModalParameters} from '../../../storage/actions';
import {TFilteredPatientAnalyzesComponentProps} from '../../../types';
import Loader from '../loader';
import {DateTime} from 'luxon';

const PatientAnalyzesComponent: React.FC<TFilteredPatientAnalyzesComponentProps> = ({filteredPatientAnalyzes}) => {
  const {dictionary} = useLanguageDictionary();
  const dispatch = useDispatch();

  const createGraphicKey = (organs: any) => {
    let organsArray_6 = [' -', ' -', ' -', ' -', ' -', ' -'];
    if (organs) {
      if (organs.length) {
        if (organs.length === 12) {
          return `[${(organs.map((organ: any) => ' ' + organ.state)).toString()} ]`
        }
        if (organs.length <= 7) {
          for (let i = 0; i < organs.length; i++) {
            if (organs[i].name === 'heart') {
              organsArray_6[0] = organs[i].state.toString().length && ' ' + organs[i].state.toString();
            }
            if (organs[i].name === 'liver') {
              organsArray_6[1] = organs[i].state.toString().length && ' ' + organs[i].state.toString();
            }
            if (organs[i].name === 'kidney') {
              organsArray_6[2] = organs[i].state.toString().length && ' ' + organs[i].state.toString();
            }
            if (organs[i].name === 'lungs') {
              organsArray_6[3] = organs[i].state.toString().length && ' ' + organs[i].state.toString();
            }
            if (organs[i].name === 'stomach') {
              organsArray_6[4] = organs[i].state.toString().length && ' ' + organs[i].state.toString();
            }
            if (organs[i].name === 'capillary') {
              organsArray_6[5] = organs[i].state.toString().length && ' ' + organs[i].state.toString();
            }
          }
          return `[${organsArray_6.join(',')} ]`
        }
      }
    } else {
      return '-';
    }
  };

  return (
    <div className="PatientAnalyzes">
      <div className="PatientAnalyzes__block">
        <div className="PatientAnalyzes__content">
          <div className="PatientAnalyzes__table">
            <div className="PatientAnalyzes__table-thead">
              <span className="PatientAnalyzes__table-th PatientAnalyzes__table-column">
                {dictionary['analysisDate']}
              </span>
              <span className="PatientAnalyzes__table-th PatientAnalyzes__table-column">
                {dictionary['label']}
              </span>
              <span className="PatientAnalyzes__table-th PatientAnalyzes__table-column">
                {dictionary['analysisDevice']}
              </span>
              <span className="PatientAnalyzes__table-th PatientAnalyzes__table-column">
                {dictionary['success']}
              </span>
              <span className="PatientAnalyzes__table-th PatientAnalyzes__table-column">
                {dictionary['graphicKey']}
              </span>
              <span className="PatientAnalyzes__table-th PatientAnalyzes__table-column">
                {dictionary['debuggingReport']}
              </span>
              <span className="PatientAnalyzes__table-th PatientAnalyzes__table-column">
                {dictionary['action']}
              </span>
            </div>
          </div>
          <div className="PatientAnalyzes__table">
            <div className="PatientAnalyzes__table-tbody">
              {filteredPatientAnalyzes ? (
                <ul className="PatientAnalyzes__table-list">
                  {filteredPatientAnalyzes.map((item: any) => (
                    <li
                      key={`PatientAnalyzes__table-list-item_${item.id}`}
                      className="PatientAnalyzes__table-list-item"
                    >
                      <span className="PatientAnalyzes__table-td PatientAnalyzes__table-column">
                        {DateTime.fromISO(item.time, {zone: "utc"}).toLocal().toFormat('dd/MM/yyyy')} {DateTime.fromISO(item.time, {zone: "utc"}).toLocal().toFormat('HH:mm')}
                      </span>
                      <span className="PatientAnalyzes__table-td PatientAnalyzes__table-column">
                        {dictionary[`${item.description.mark}`]}
                      </span>
                      <span className="PatientAnalyzes__table-td PatientAnalyzes__table-column">
                        {item.device_info.manufacturer} {item.device_info.model}
                      </span>
                      <span className="PatientAnalyzes__table-td PatientAnalyzes__table-column">
                        {dictionary[`${item.success ? 'yes' : 'no'}`]}
                      </span>
                      <span className="PatientAnalyzes__table-td PatientAnalyzes__table-column">
                        {createGraphicKey(item.organs)}
                      </span>
                      <span className="PatientAnalyzes__table-td PatientAnalyzes__table-column">
                        <a
                          href={`http://${localStorage.getItem('userBackendDomain')}/debug/${item.id}`}
                          className="PatientAnalyzes__table-watch-analysis"
                          target="_blank"
                        >
                          {dictionary['watch']}
                        </a>
                      </span>
                      <span className="PatientAnalyzes__table-td PatientAnalyzes__table-column">
                        {item.success ? (
                          <button
                            className="PatientAnalyzes__table-show-inspection"
                            type="button"
                            onClick={() => dispatch(setModalParameters(true, 'analysisData', item))}
                          >
                            <SVGInline
                              className="SVGInline_stroke"
                              svg={`${icons['file_text']}`}
                            />
                          </button>
                        ) : ''}
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <Loader/>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientAnalyzesComponent;
