import './index.scss';
import React from 'react';
import {useLanguageDictionary} from '../../../utilities';
import {useDispatch, useSelector} from 'react-redux';
import {setModalParameters, setPatientLastReception} from '../../../storage/actions';
import {TStorageProps} from '../../../types';

const NotificationComponent: React.FC = () => {
  const {patientReceptions} = useSelector((storage: TStorageProps) => ({patientReceptions: storage.patients.patientReceptions}));
  const {dictionary} = useLanguageDictionary();
  const dispatch = useDispatch();

  return (
    <>
      <span className="modal__title">
        {dictionary['notification']}
      </span>
      <div className="Notification">
        <div className="Notification__block">
          <div className="Notification__content">
            <p className="Notification__text">
              {dictionary['previouslyGeneratedReportsWereFoundForTheUserWantToFillInDataFromAPreviousReport']}
            </p>
            <div className="Notification__actions">
              <button
                className="btn btn_default Notification__button"
                type="button"
                onClick={() => {
                  dispatch(setModalParameters(false));
                  setTimeout(() => {
                    dispatch(setModalParameters(true, 'newReport', {}));
                  });
                }}
              >
                {dictionary['skip']}
              </button>
              <button
                className="btn btn_primary Notification__button"
                type="button"
                onClick={() => {
                  dispatch(setModalParameters(false));
                  setTimeout(() => {
                    dispatch(setPatientLastReception(patientReceptions[0]));
                    dispatch(setModalParameters(true, 'newReport', {}));
                  });
                }}
              >
                {dictionary['fillIn']}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotificationComponent;
