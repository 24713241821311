import update from 'react-addons-update';
import {TYPES} from '../../actions';

const initialStorage = {
  isUserAuthorized: false
};

const userReducer = (storage = initialStorage, action: any) => {
  switch (action.type) {
    case TYPES.USER.SET_IS_USER_AUTHORIZED: {
      return update(storage, {
        isUserAuthorized: {$set: action.value}
      });
    }
    default:
      return storage;
  }
}

export default userReducer;