import {TYPES} from '../';
import {switchMatch} from '../../../utilities';

export const setIsMobileBrowser = (value: boolean) => ({
  type: TYPES.COMMON.SET_IS_MOBILE_BROWSER,
  value
});

export const setIsScreenResolution = (value: boolean) => ({
  type: TYPES.COMMON.SET_IS_MOBILE_SCREEN_RESOLUTION,
  value
});

export const setLanguageDictionary = (value: string) => (dispatch: any) => {
  const language = switchMatch(value, {
    us: 'en',
    cn: 'zh',
    default: value
  });
  localStorage.setItem('selectedUserLanguage', language);
  import(`../../../localization/${language}.js`)
    .then((data: any) => {
      dispatch({
        type: TYPES.COMMON.SET_LANGUAGE_DICTIONARY,
        payload: {...data, language}
      });
    })
    .then(() => {
      dispatch({
        type: TYPES.COMMON.SET_IS_THE_APP_LOADED,
        value: true
      });
    })
}

export const setModalParameters = (value: boolean, type: string | undefined = undefined, options: any = undefined) => ({
  type: TYPES.COMMON.SET_MODAL_PARAMETERS,
  payload: {value, type, options}
});