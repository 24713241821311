import React from 'react';
import {TSwitchesComponentProps} from '../../../types';
import classNames from 'classnames';

const SwitchesComponent: React.FC<TSwitchesComponentProps> = ({
                                                                leftButtonText,
                                                                rightButtonText,
                                                                isLeftButtonActive,
                                                                isRightButtonActive,
                                                                handlerLeftButton,
                                                                handlerRightButton
                                                              }) => {
  return (
    <div className="switches">
      <button
        className={classNames('switches__button', {
          'switches__button_active': isLeftButtonActive
        })}
        onClick={handlerLeftButton}
      >
        {leftButtonText}
      </button>
      <button
        className={classNames('switches__button', {
          'switches__button_active': isRightButtonActive
        })}
        onClick={handlerRightButton}
      >
        {rightButtonText}
      </button>
    </div>
  )
}

export default SwitchesComponent;
