import update from 'react-addons-update';
import {TYPES} from '../../actions';

const initialStorage = {
  isMobileBrowser: false,
  isMobileScreenResolution: false,
  languageDictionary: {
    selectedUserLanguage: undefined,
    dictionaryOfTheCurrentLanguage: undefined
  },
  isAppLanguageLoaded: false,
  modal: {
    isModalOpen: false,
    modalType: undefined,
    modalOptions: undefined
  }
};

const commonReducer = (storage = initialStorage, action: any) => {
  switch (action.type) {
    case TYPES.COMMON.SET_IS_MOBILE_BROWSER: {
      return update(storage, {
        isMobileBrowser: {$set: action.value}
      });
    }
    case TYPES.COMMON.SET_IS_MOBILE_SCREEN_RESOLUTION: {
      return update(storage, {
        isMobileScreenResolution: {$set: action.value}
      });
    }
    case TYPES.COMMON.SET_LANGUAGE_DICTIONARY: {
      return update(storage, {
        languageDictionary: {
          selectedUserLanguage: {$set: action.payload.language},
          dictionaryOfTheCurrentLanguage: {$set: action.payload.default}
        }
      });
    }
    case TYPES.COMMON.SET_IS_THE_APP_LOADED: {
      return update(storage, {
        isAppLanguageLoaded: {$set: action.value}
      });
    }
    case TYPES.COMMON.SET_MODAL_PARAMETERS: {
      return update(storage, {
        modal: {
          isModalOpen: {$set: action.payload.value},
          modalType: {$set: action.payload.type},
          modalOptions: {$set: action.payload.options},
        }
      });
    }
    default:
      return storage;
  }
}

export default commonReducer;