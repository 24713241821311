import {TYPES} from '../';

export const setPatientsList = (data: any) => ({
  type: TYPES.PATIENTS.SET_PATIENTS_LIST,
  data
});

export const setPatientsListItem = (data: any) => ({
  type: TYPES.PATIENTS.SET_PATIENTS_LIST_ITEM,
  data
});

export const setPatient = (data: any) => ({
  type: TYPES.PATIENTS.SET_PATIENT,
  data
});

export const setPatientReceptions = (data: any) => ({
  type: TYPES.PATIENTS.SET_PATIENT_RECEPTIONS,
  data
});

export const setPatientAnalyzes = (data: any) => ({
  type: TYPES.PATIENTS.SET_PATIENT_ANALYZES,
  data
});

export const setPatientLastReception = (data: any) => ({
  type: TYPES.PATIENTS.SET_PATIENT_LAST_RECEPTIONS,
  data
});