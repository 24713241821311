import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import App from './App';
import storageInitialization from './storage';

ReactDOM.render((
    <React.StrictMode>
      <Provider store={storageInitialization()}>
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  ), document.getElementById('root')
);