import {applyMiddleware, createStore} from 'redux';
import reducersInitialization from './reducers';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';

const storageInitialization = () => {

  return createStore(
    reducersInitialization,
    composeWithDevTools(
      applyMiddleware(thunk)
    )
  );
}

export default storageInitialization;