import api from '../../';

const login = (login: string, password: string) => {
  return new Promise((resolve, reject) => {
    api.post('auth', {login, password})
      .then(
        (response: any) => {
          resolve(response);
        },
        (response: any) => {
          reject(response);
        }
      )
      .catch((error: any) => {
        reject(error);
      });
  });
}

const authentication = {login};
export default authentication;