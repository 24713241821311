import './index.scss';
import React, {useEffect, useState} from 'react';
import {checkingMailForValidity, useLanguageDictionary} from '../../../utilities';
import Input from '../../../components/ui/input';
import Select from '../../../components/ui/select';
import ReactHtmlParser from 'react-html-parser';
import {useFormik} from 'formik';
import {useDispatch} from 'react-redux';
import user from '../../../api/requests/patients';
import {setModalParameters, setPatientsList} from '../../../storage/actions';
import classNames from 'classnames';

const AddingPatientComponent: React.FC = () => {
  const {dictionary} = useLanguageDictionary();
  const dispatch = useDispatch();
  const [isAllergyFieldVisible, setIsAllergyFieldVisible] = useState<boolean>(false);
  const [wasTheFirstChoice, setWasTheFirstChoice] = useState<boolean>(false);
  const [wasThereSearchForPatient, setWasThereSearchForPatient] = useState<boolean>(false);
  const [isPatientFoundStateDone, setIsPatientFoundStateDone] = useState<boolean>(false);
  const [patientFoundText, setPatientFoundText] = useState<string>('');
  const [patientData, setPatientData] = useState<any>({
    surname: '',
    name: '',
    middleName: '',
    gender: '',
    byear: '',
    contacts: '',
    physique: '',
    allergy: '',
    initialVisibilityOfAllergies: 'false'
  });
  const [patientLogin, setPatientLogin] = useState<string>('');
  const [patientLoginError, setPatientLoginError] = useState<string | undefined>(undefined);
  const [isButtonAddVisible, setIsButtonAddVisible] = useState<boolean>(true);
  const [isPatientHasBeenFound, setIsPatientHasBeenFound] = useState<boolean>(false);
  const validate = (values: {
    pk: number,
    surname: string,
    name: string,
    middleName: string,
    gender: number | '',
    byear: number | '',
    contacts: string,
    physique: string | '',
    allergy: string,
    initialVisibilityOfAllergies: string
  }) => {
    const errors: any = {};
    if (!values.name || !values.name.length) {
      errors.name = dictionary['requiredFields'];
    }
    if (!values.byear || !values.byear.toString().length) {
      errors.byear = dictionary['requiredFields'];
    }
    if (!values.contacts || !values.contacts.length) {
      errors.contacts = dictionary['requiredFields'];
    }
    if (!values.gender.toString().length) {
      errors.gender = dictionary['requiredFields'];
    }
    if (!values.physique.length) {
      errors.physique = dictionary['requiredFields'];
    }
    if ((!wasTheFirstChoice && !values.allergy.length) || (isAllergyFieldVisible && !values.allergy.length)) {
      errors.allergy = dictionary['requiredFields'];
    }
    return errors;
  };

  const handlerAfterOnSubmit = (pk: number) => {
    user.sendPatientConsultation(pk, "0")
      .then(
        () => {
          user.getPatientsList()
            .then(
              (data: any) => data.json()
            )
            .then(
              (data: any) => dispatch(setPatientsList(data))
            )
            .catch((error: any) => error);
          dispatch(setModalParameters(false));
        }
      )
      .catch((error: any) => error);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pk: patientData.pk,
      surname: patientData.surname,
      name: patientData.name,
      middleName: patientData.middleName,
      gender: patientData.gender,
      byear: patientData.byear,
      contacts: patientData.contacts,
      physique: patientData.physique,
      allergy: patientData.allergy,
      initialVisibilityOfAllergies: patientData.initialVisibilityOfAllergies
    },
    validate,
    onSubmit: (values: any) => {
      if (isPatientHasBeenFound) {
        user.sendModifiedPatientData({
          pk: values.pk,
          allergy: isAllergyFieldVisible ? values.allergy : '',
          byear: values.byear,
          contacts: values.contacts,
          doctors_full_name: `${values.surname}_${values.name}_${values.middleName}`,
          gender: values.gender,
          physique: values.physique
        })
          .then(
            (data: any) => data.json()
          )
          .then(
            (data: any) => {
              handlerAfterOnSubmit(data.pk);
            }
          )
          .catch((error: any) => error);
      } else {
        user.sendNewPatientData({
          allergy: isAllergyFieldVisible ? values.allergy : '',
          byear: values.byear,
          contacts: values.contacts,
          doctors_full_name: `${values.surname}_${values.name}_${values.middleName}`,
          gender: values.gender,
          physique: values.physique
        })
          .then(
            (data: any) => data.json()
          )
          .then(
            (data: any) => {
              handlerAfterOnSubmit(data.pk);
            }
          )
          .catch((error: any) => error);
      }
    }
  });
  const optionsGender = {
    0: dictionary['W'],
    1: dictionary['M']
  };
  const optionsPhysique = {
    1: dictionary['wood'],
    2: dictionary['metal'],
    3: dictionary['fire'],
    4: dictionary['water'],
    5: dictionary['earth'],
    6: dictionary['woodMetal'],
    7: dictionary['woodFire'],
    8: dictionary['woodMetalFire'],
    9: dictionary['woodEarth'],
    10: dictionary['woodEarthFire'],
    11: dictionary['metalFire'],
    12: dictionary['earthFire'],
    13: dictionary['earthMetal']
  };
  const optionsAllergy = {
    true: dictionary['yes'],
    false: dictionary['no']
  };

  useEffect(() => {
    patientData.allergy && patientData.allergy.length && setIsAllergyFieldVisible(true);
  }, [patientData]);

  const handlerFindPatient = (email: string) => {
    setPatientData({
      surname: '',
      name: '',
      middleName: '',
      gender: '',
      byear: '',
      contacts: '',
      physique: '',
      allergy: '',
      initialVisibilityOfAllergies: ''
    });
    setWasTheFirstChoice(false);
    setIsAllergyFieldVisible(false);
    if (checkingMailForValidity(email)) {
      setPatientLoginError(undefined);
      user.findPatient(email)
        .then(
          (data: any) => data.json()
        )
        .then(
          (data: any) => {
            if (!data.length) {
              setWasThereSearchForPatient(true);
              setIsPatientFoundStateDone(false);
              setPatientFoundText(dictionary['patientNotFound']);
            } else {
              user.findPatientData(data[0].id)
                .then(
                  (data: any) => data.json()
                )
                .then(
                  (data: any) => {
                    if (data.doctors_full_name === null) {
                      setPatientData({
                        pk: data.pk,
                        surname: '',
                        name: data.full_name,
                        middleName: '',
                        gender: data.gender ? data.gender : '',
                        byear: data.byear ? data.byear : '',
                        contacts: '',
                        physique: '',
                        allergy: '',
                        initialVisibilityOfAllergies: 'false'
                      });
                      setWasThereSearchForPatient(true);
                      setIsPatientFoundStateDone(true);
                      setPatientFoundText(dictionary['patientHasBeenFound']);
                      setIsPatientHasBeenFound(true);
                    } else {
                      setPatientData({
                        surname: data.doctors_full_name.substring(0, data.doctors_full_name.indexOf('_')) || '',
                        name: data.doctors_full_name.substring(data.doctors_full_name.indexOf('_') + 1, data.doctors_full_name.lastIndexOf('_')),
                        middleName: data.doctors_full_name.substring(data.doctors_full_name.length, data.doctors_full_name.lastIndexOf('_') + 1) || '',
                        gender: data.gender ? data.gender : '',
                        byear: data.byear ? data.byear : '',
                        contacts: data.contacts ? data.contacts : '',
                        physique: data.physique ? data.physique : '',
                        allergy: data.allergy && data.allergy.length ? data.allergy : '',
                        initialVisibilityOfAllergies: data.allergy && data.allergy.length ? 'true' : 'false'
                      });
                      setWasTheFirstChoice(true);
                      setWasThereSearchForPatient(true);
                      setIsPatientFoundStateDone(true);
                      setPatientFoundText(dictionary['patientHasAlreadyBeenAdded']);
                      setIsButtonAddVisible(false);
                    }
                  }
                )
                .catch((error: any) => error);
            }
          }
        )
        .catch((error: any) => error);
    } else {
      setPatientLoginError(dictionary['theValueMustMatchTheEmailTemplate']);
    }
  }

  return (
    <>
      <span className="modal__title">
        {dictionary['patientCard']}
      </span>
      <div className="AddingPatient">
        <div className="AddingPatient__block">
          <div className="AddingPatient__content">
            <form onSubmit={formik.handleSubmit}>
              <div className="AddingPatient__form">
                <div className="AddingPatient__form-fields">
                  <div className="AddingPatient__form-fields-point">
                    <Input
                      labelClass="AddingPatient__form-fields-point-label"
                      type="text"
                      view="primary"
                      calledFrom="AddingPatient__form-fields-point"
                      placeholder={dictionary['enterYourEmailToSearch']}
                      value={patientLogin}
                      done={wasThereSearchForPatient && isPatientFoundStateDone && patientFoundText}
                      error={wasThereSearchForPatient && !isPatientFoundStateDone ? patientFoundText : patientLoginError}
                      disabled={isPatientHasBeenFound}
                      handlerOnChange={(value: string) => {
                        setWasThereSearchForPatient(false);
                        setPatientLogin(value);
                        if (checkingMailForValidity(value)) {
                          setPatientLoginError(undefined);
                        } else {
                          if (value.length) {
                            setPatientLoginError(dictionary['theValueMustMatchTheEmailTemplate']);
                          } else {
                            setPatientLoginError(undefined);
                          }
                        }
                        setIsButtonAddVisible(true);
                      }}
                    />
                    <button
                      className="btn btn_default AddingPatient__form-fields-point-find"
                      type="button"
                      onClick={() => handlerFindPatient(patientLogin)}
                      disabled={isPatientHasBeenFound}
                    >
                      {dictionary['find']}
                    </button>
                  </div>
                  <div className="AddingPatient__form-fields-point">
                    <span className="modal__label">
                      {dictionary['surname']}
                    </span>
                    <Input
                      type="text"
                      view="primary"
                      calledFrom="AddingPatient__form-fields-point"
                      placeholder={dictionary['surname']}
                      value={formik.values.surname}
                      handlerOnChange={(value: string) => formik.setFieldValue('surname', value)}
                    />
                  </div>
                  <div className="AddingPatient__form-fields-point">
                    <span className="modal__label">
                      {dictionary['name']}
                    </span>
                    <Input
                      type="text"
                      view="primary"
                      calledFrom="AddingPatient__form-fields-point"
                      placeholder={dictionary['name']}
                      value={formik.values.name}
                      error={formik.errors.name}
                      handlerOnChange={(value: string) => formik.setFieldValue('name', value)}
                    />
                  </div>
                  <div className="AddingPatient__form-fields-point">
                    <span className="modal__label">
                      {dictionary['middleName']}
                    </span>
                    <Input
                      type="text"
                      view="primary"
                      calledFrom="AddingPatient__form-fields-point"
                      placeholder={dictionary['middleName']}
                      value={formik.values.middleName}
                      handlerOnChange={(value: string) => formik.setFieldValue('middleName', value)}
                    />
                  </div>
                  <div className="AddingPatient__form-fields-point">
                    <span className="modal__label">
                      {dictionary['gender']}
                    </span>
                    <Select
                      view="primary"
                      calledFrom="AddingPatient__form-fields-point"
                      value={formik.values.gender}
                      error={formik.errors.gender}
                      handlerOnChange={(eValue: string) => formik.setFieldValue('gender', Number(eValue))}
                      options={optionsGender}
                    />
                  </div>
                  <div className="AddingPatient__form-fields-point">
                    <span className="modal__label">
                      {dictionary['yearOfBirth']}
                    </span>
                    <Input
                      type="text"
                      view="primary"
                      calledFrom="AddingPatient__form-fields-point"
                      placeholder={dictionary['yearOfBirth']}
                      value={formik.values.byear}
                      error={formik.errors.byear}
                      handlerOnChange={(value: string) => formik.setFieldValue('byear', Number(value))}
                    />
                  </div>
                  <div className="AddingPatient__form-fields-point">
                    <span className="modal__label">
                      {dictionary['telephone']}
                    </span>
                    <Input
                      type="text"
                      view="primary"
                      calledFrom="AddingPatient__form-fields-point"
                      placeholder={dictionary['telephone']}
                      value={formik.values.contacts}
                      error={formik.errors.contacts}
                      handlerOnChange={(value: string) => formik.setFieldValue('contacts', value)}
                    />
                  </div>
                  <div className="AddingPatient__form-fields-point">
                    <span className="modal__label">
                      {dictionary['constitution']}
                    </span>
                    <Select
                      view="primary"
                      calledFrom="AddingPatient__form-fields-point"
                      value={formik.values.physique}
                      error={formik.errors.physique}
                      showError={true}
                      handlerOnChange={(eValue: string) => formik.setFieldValue('physique', eValue)}
                      options={optionsPhysique}
                    />
                  </div>
                  <div className="AddingPatient__form-fields-point">
                    <span className="modal__label">
                      {dictionary['allergy']}
                    </span>
                    <Select
                      view="primary"
                      calledFrom="AddingPatient__form-fields-point"
                      value={wasTheFirstChoice ? `${isAllergyFieldVisible}` : ''}
                      error={formik.errors.allergy}
                      showError={false}
                      handlerOnChange={(eValue: string) => {
                        !wasTheFirstChoice && setWasTheFirstChoice(true);
                        setIsAllergyFieldVisible(eValue === 'true');
                        formik.setFieldValue('initialVisibilityOfAllergies', eValue);
                      }}
                      options={optionsAllergy}
                    />
                  </div>
                  {isAllergyFieldVisible && (
                    <div className="AddingPatient__form-fields-point">
                      <span className="modal__label">
                        {ReactHtmlParser(dictionary['allergyData'])}
                      </span>
                      <textarea
                        className={classNames('textarea textarea_primary AddingPatient__form-fields-point-textarea', {
                          'textarea_error': formik.errors.allergy && true
                        })}
                        defaultValue={formik.values.allergy}
                        onChange={(e: any) => formik.setFieldValue('allergy', e.target.value)}
                      />
                      {formik.errors.allergy && (
                        <span className="AddingPatient__form-fields-point-error">
                          {formik.errors.allergy}
                        </span>
                      )}
                    </div>
                  )}
                </div>
                {isButtonAddVisible && (
                  <button
                    className="btn btn_primary AddingPatient__add"
                    type="submit"
                  >
                    {dictionary['add']}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddingPatientComponent;