export const TYPES = {
  COMMON: {
    SET_IS_MOBILE_BROWSER: 'SET_IS_MOBILE_BROWSER',
    SET_IS_MOBILE_SCREEN_RESOLUTION: 'SET_IS_MOBILE_SCREEN_RESOLUTION',
    SET_LANGUAGE_DICTIONARY: 'SET_LANGUAGE_DICTIONARY',
    SET_IS_THE_APP_LOADED: 'SET_IS_THE_APP_LOADED',
    SET_MODAL_PARAMETERS: 'SET_MODAL_PARAMETERS'
  },
  USER: {
    SET_IS_USER_AUTHORIZED: 'SET_IS_USER_AUTHORIZED'
  },
  PATIENTS: {
    SET_PATIENTS_LIST: 'SET_PATIENTS_LIST',
    SET_PATIENTS_LIST_ITEM: 'SET_PATIENTS_LIST_ITEM',
    SET_PATIENT: 'SET_PATIENT',
    SET_PATIENT_RECEPTIONS: 'SET_PATIENT_RECEPTIONS',
    SET_PATIENT_ANALYZES: 'SET_PATIENT_ANALYZES',
    SET_PATIENT_LAST_RECEPTIONS: 'SET_PATIENT_LAST_RECEPTIONS'
  }
};