import React, {ChangeEvent, useState} from 'react';
import {TInputComponentProps} from '../../../types';
import SVGInline from 'react-svg-inline';
import icons from '../../../assets/images/icons/icons.json';

const InputComponent: React.FC<TInputComponentProps> = (props) => {
  const {labelClass = '', type, view, calledFrom, icon, placeholder, value, done, error, required, disabled, handlerOnChange} = props;
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <label className={`label ${labelClass}`}>
      {icon && (
        <span className="label__icon">
          {type === 'email' && (
            <SVGInline
              className="SVGInline_fill"
              svg={`${icons['mail']}`}
            />
          )}
            {type === 'password' && (
              <SVGInline
                className="SVGInline_stroke"
                svg={`${icons['key']}`}
              />
          )}
        </span>
      )}
      <input
        className={`input input_${type} input_${view} ${calledFrom ? `${calledFrom}-input` : ''} ${error ? 'input_error' : ''}`}
        type={isPasswordVisible ? 'text' : type}
        placeholder={placeholder}
        value={value}
        required={required}
        disabled={disabled}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handlerOnChange!(e.target.value)
        }}
      />
      {type === 'password' && (
        <button
          className="input__change-view"
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          type="button"
        >
          <SVGInline
            className="SVGInline_fill"
            svg={`${icons[isPasswordVisible ? 'eye_open' : 'eye_close']}`}
          />
        </button>
      )}
      {error && (
        <span className="input__error-message">
          {error}
        </span>
      )}
      {done && (
        <span className="input__done-message">
          {done}
        </span>
      )}
    </label>
  );
}

export default InputComponent;
