import './index.scss';
import React from 'react';
import {switchMatch, useLanguageDictionary} from '../../../utilities';
import SignInForm from '../../../components/common/forms/sign_in';
import ReactFlagsSelect from 'react-flags-select';
import {setLanguageDictionary} from '../../../storage/actions';
import {useDispatch, useSelector} from 'react-redux';
import {TStorageProps} from '../../../types';

const SignInPage: React.FC = () => {
  const {selectedUserLanguage} = useSelector((storage: TStorageProps) => ({selectedUserLanguage: storage.common.languageDictionary.selectedUserLanguage}));
  const {dictionary} = useLanguageDictionary();
  const dispatch = useDispatch();

  return (
    <div className="SignIn">
      <div className="SignIn__block">
        <div className="SignIn__content">
          <span className="SignIn__title">
            {dictionary['signIn']}
          </span>
          <div className="SignIn__form">
            <SignInForm/>
          </div>
          <div className="SignIn__language">
            <span className="SignIn__language-title">
              {dictionary['chooseLanguage']}
            </span>
            <div className="SignIn__language-selection">
              <ReactFlagsSelect
                selectButtonClassName={
                  switchMatch(selectedUserLanguage, {
                    en: 'US',
                    zh: 'CN',
                    default: selectedUserLanguage
                  }).toUpperCase()}
                placeholder={dictionary['selectLanguage']}
                selected={
                  switchMatch(selectedUserLanguage, {
                    en: 'US',
                    zh: 'CN',
                    default: selectedUserLanguage
                  }).toUpperCase()
                }
                countries={["US", "RU", "CN"]}
                customLabels={{"US": "English", "RU": "Русский", "CN": "中文"}}
                onSelect={(countryCode: string) => {
                  dispatch(setLanguageDictionary(countryCode.toLocaleLowerCase()))
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignInPage;
