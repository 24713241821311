import './index.scss';
import React from 'react';
import Loader from '../../../components/common/loader';

const NotFoundPage: React.FC = () => {

  return (
    <div className="NotFound">
      <div className="NotFound__block">
        <div className="NotFound__content">
          Not found
          <Loader isFixed={true}/>
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;