import {useSelector} from 'react-redux';
import {TStorageProps} from '../../types';

export const useLanguageDictionary: any = () => {
  const {dictionary} = useSelector((storage: TStorageProps) => ({dictionary: storage.common.languageDictionary.dictionaryOfTheCurrentLanguage}));

  return {
    dictionary, getDictionaryMeaning: (key: string) => {
      if (dictionary === undefined || Object.keys(dictionary).length === 0) {
        return '?';
      }
      return key in dictionary ? dictionary[key] : '?';
    }
  };
};
