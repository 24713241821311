import './index.scss';
import React, {useEffect, useState} from 'react';
import {useLanguageDictionary} from '../../../utilities';
import Input from '../../../components/ui/input';
import ReactHtmlParser from 'react-html-parser';
import {useFormik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {TStorageProps} from '../../../types';
import user from '../../../api/requests/patients';
import {setModalParameters, setPatientsList} from '../../../storage/actions';
import Select from '../../ui/select';
import classNames from 'classnames';

const PatientCardComponent: React.FC = () => {
  const {patient} = useSelector((storage: TStorageProps) => ({patient: storage.patients.patient}));
  const {dictionary} = useLanguageDictionary();
  const dispatch = useDispatch();
  const [isAllergyFieldVisible, setIsAllergyFieldVisible] = useState<boolean>(false);
  const [isButtonSaveVisible, setIsButtonSaveVisible] = useState<boolean>(false);
  const [patientData, setPatientData] = useState<any>(undefined);

  const validate = (values: {
    pk: number,
    surname: string,
    name: string,
    middleName: string,
    gender: number,
    byear: number,
    contacts: string,
    physique: string,
    allergy: string,
    initialVisibilityOfAllergies: string
  }) => {
    const errors: any = {};
    if (!values.name || !values.name.length) {
      errors.name = dictionary['requiredFields'];
    }
    if (!values.byear || !values.byear.toString().length) {
      errors.byear = dictionary['requiredFields'];
    }
    if (!values.contacts || !values.contacts.length) {
      errors.contacts = dictionary['requiredFields'];
    }
    if (JSON.stringify(Object.values(patientData)) !== JSON.stringify(Object.values(values))) {
      setIsButtonSaveVisible(true);
    } else {
      setIsButtonSaveVisible(false);
    }
    if (!values.gender.toString().length) {
      errors.gender = dictionary['requiredFields'];
    }
    if (!values.physique.length) {
      errors.physique = dictionary['requiredFields'];
    }
    if (isAllergyFieldVisible && !values.allergy.length) {
      errors.allergy = dictionary['requiredFields'];
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      pk: patient.pk,
      surname: patient.doctors_full_name.substring(0, patient.doctors_full_name.indexOf('_')) || '',
      name: patient.doctors_full_name.substring(patient.doctors_full_name.indexOf('_') + 1, patient.doctors_full_name.lastIndexOf('_')),
      middleName: patient.doctors_full_name.substring(patient.doctors_full_name.length, patient.doctors_full_name.lastIndexOf('_') + 1) || '',
      gender: patient.gender,
      byear: patient.byear,
      contacts: patient.contacts,
      physique: patient.physique,
      allergy: patient.allergy,
      initialVisibilityOfAllergies: patient.allergy && patient.allergy.length ? 'true' : 'false'
    },
    validate,
    onSubmit: (values: any) => {
      user.sendModifiedPatientData({
        pk: values.pk,
        allergy: isAllergyFieldVisible ? values.allergy : '',
        byear: values.byear,
        contacts: values.contacts,
        doctors_full_name: `${values.surname}_${values.name}_${values.middleName}`,
        gender: values.gender,
        physique: values.physique
      })
        .then(
          () => {
            user.getPatientsList()
              .then(
                (data: any) => data.json()
              )
              .then(
                (data: any) => dispatch(setPatientsList(data))
              )
              .catch((error: any) => error);
            dispatch(setModalParameters(false));
          }
        )
        .catch((error: any) => error);
    }
  });

  useEffect(() => {
    patient.allergy && patient.allergy.length && setIsAllergyFieldVisible(true);
    setPatientData({
      surname: patient.doctors_full_name.substring(0, patient.doctors_full_name.indexOf('_')) || '',
      name: patient.doctors_full_name.substring(patient.doctors_full_name.indexOf('_') + 1, patient.doctors_full_name.lastIndexOf('_')),
      middleName: patient.doctors_full_name.substring(patient.doctors_full_name.length, patient.doctors_full_name.lastIndexOf('_') + 1) || '',
      gender: patient.gender,
      byear: patient.byear,
      contacts: patient.contacts,
      physique: patient.physique,
      allergy: patient.allergy,
      pk: patient.pk,
      initialVisibilityOfAllergies: patient.allergy && patient.allergy.length ? 'true' : 'false'
    })
  }, [patient]);

  const optionsGender = {
    0: dictionary['W'],
    1: dictionary['M']
  };
  const optionsPhysique = {
    1: dictionary['wood'],
    2: dictionary['metal'],
    3: dictionary['fire'],
    4: dictionary['water'],
    5: dictionary['earth'],
    6: dictionary['woodMetal'],
    7: dictionary['woodFire'],
    8: dictionary['woodMetalFire'],
    9: dictionary['woodEarth'],
    10: dictionary['woodEarthFire'],
    11: dictionary['metalFire'],
    12: dictionary['earthFire'],
    13: dictionary['earthMetal']
  };
  const optionsAllergy = {
    true: dictionary['yes'],
    false: dictionary['no']
  };

  return (
    <>
      <span className="modal__title">
        {dictionary['patientCard']}
      </span>
      <div className="PatientCard">
        <div className="PatientCard__block">
          <div className="PatientCard__content">
            <form onSubmit={formik.handleSubmit}>
              <div className="PatientCard__form">
                <div className="PatientCard__form-fields">
                  <div className="PatientCard__form-fields-point">
                    <span className="modal__label">
                      {dictionary['surname']}
                    </span>
                    <Input
                      type="text"
                      view="primary"
                      calledFrom="PatientCard__form-fields-point"
                      placeholder={dictionary['surname']}
                      value={formik.values.surname}
                      handlerOnChange={(value: string) => formik.setFieldValue('surname', value)}
                    />
                  </div>
                  <div className="PatientCard__form-fields-point">
                    <span className="modal__label">
                      {dictionary['name']}
                    </span>
                    <Input
                      type="text"
                      view="primary"
                      calledFrom="PatientCard__form-fields-point"
                      placeholder={dictionary['name']}
                      value={formik.values.name}
                      error={formik.errors.name}
                      handlerOnChange={(value: string) => formik.setFieldValue('name', value)}
                    />
                  </div>
                  <div className="PatientCard__form-fields-point">
                    <span className="modal__label">
                      {dictionary['middleName']}
                    </span>
                    <Input
                      type="text"
                      view="primary"
                      calledFrom="PatientCard__form-fields-point"
                      placeholder={dictionary['middleName']}
                      value={formik.values.middleName}
                      handlerOnChange={(value: string) => formik.setFieldValue('middleName', value)}
                    />
                  </div>
                  <div className="PatientCard__form-fields-point">
                    <span className="modal__label">
                      {dictionary['gender']}
                    </span>
                    <Select
                      view="primary"
                      calledFrom="PatientCard__form-fields-point"
                      value={formik.values.gender}
                      error={formik.errors.gender}
                      handlerOnChange={(eValue: string) => formik.setFieldValue('gender', Number(eValue))}
                      options={optionsGender}
                    />
                  </div>
                  <div className="PatientCard__form-fields-point">
                    <span className="modal__label">
                      {dictionary['yearOfBirth']}
                    </span>
                    <Input
                      type="text"
                      view="primary"
                      calledFrom="PatientCard__form-fields-point"
                      placeholder={dictionary['yearOfBirth']}
                      value={formik.values.byear}
                      error={formik.errors.byear}
                      handlerOnChange={(value: string) => formik.setFieldValue('byear', Number(value))}
                    />
                  </div>
                  <div className="PatientCard__form-fields-point">
                    <span className="modal__label">
                      {dictionary['telephone']}
                    </span>
                    <Input
                      type="text"
                      view="primary"
                      calledFrom="PatientCard__form-fields-point"
                      placeholder={dictionary['telephone']}
                      value={formik.values.contacts}
                      error={formik.errors.contacts}
                      handlerOnChange={(value: string) => formik.setFieldValue('contacts', value)}
                    />
                  </div>
                  <div className="PatientCard__form-fields-point">
                    <span className="modal__label">
                      {dictionary['constitution']}
                    </span>
                    <Select
                      view="primary"
                      calledFrom="PatientCard__form-fields-point"
                      value={formik.values.physique}
                      error={formik.errors.physique}
                      handlerOnChange={(eValue: string) => formik.setFieldValue('physique', eValue)}
                      options={optionsPhysique}
                    />
                  </div>
                  <div className="PatientCard__form-fields-point">
                    <span className="modal__label">
                      {dictionary['allergy']}
                    </span>
                    <Select
                      view="primary"
                      calledFrom="PatientCard__form-fields-point"
                      value={`${isAllergyFieldVisible}`}
                      error={formik.errors.allergy}
                      handlerOnChange={(eValue: string) => {
                        setIsAllergyFieldVisible(eValue === 'true');
                        setTimeout(() => {
                          formik.setFieldValue('initialVisibilityOfAllergies', eValue);
                        },)
                      }}
                      options={optionsAllergy}
                    />
                  </div>
                  {isAllergyFieldVisible && (
                    <div className="PatientCard__form-fields-point">
                      <span className="modal__label">
                        {ReactHtmlParser(dictionary['allergyData'])}
                      </span>
                      <textarea
                        className={classNames('textarea textarea_primary PatientCard__form-fields-point-textarea', {
                          'textarea_error': formik.errors.allergy && true
                        })}
                        defaultValue={formik.values.allergy}
                        onChange={(e: any) => formik.setFieldValue('allergy', e.target.value)}
                      />
                      {formik.errors.allergy && (
                        <span className="PatientCard__form-fields-point-error">
                          {formik.errors.allergy}
                        </span>
                      )}
                    </div>
                  )}
                </div>
                {isButtonSaveVisible && (
                  <button
                    className="btn btn_primary PatientCard__save"
                    type="submit"
                  >
                    {dictionary['save']}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default PatientCardComponent;
