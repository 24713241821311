import './index.scss';
import React, {useEffect, useState} from 'react';
import {useLanguageDictionary} from '../../../utilities';
import {TPatientsComponentProps, TStorageProps} from '../../../types';
import {setModalParameters} from '../../../storage/actions';
import {useDispatch, useSelector} from 'react-redux';
import user from '../../../api/requests/patients';
import {setPatientsList} from '../../../storage/actions/';
import Loader from '../../../components/common/loader';
import PatientsListItem from '../patients_list_item';
import Select from '../../ui/select';
import Input from '../../ui/input';

const PatientsComponent: React.FC<TPatientsComponentProps> = ({handlerSetShowPatient}) => {
  const {dictionary} = useLanguageDictionary();
  const dispatch = useDispatch();
  const {patientsList} = useSelector((storage: TStorageProps) => ({patientsList: storage.patients.patientsList}));
  const [filteredPatientsList, setFilteredPatientsList] = useState<any>(undefined);
  const [filterState, setFilterState] = useState<string>('');
  const [sampleState, setSampleState] = useState<string>('');

  useEffect(() => {
    user.getPatientsList()
      .then(
        (data: any) => data.json()
      )
      .then(
        (data: any) => {
          dispatch(setPatientsList(data));
          setFilteredPatientsList(data);
        }
      )
      .catch((error: any) => error);
  }, [dispatch]);

  useEffect(() => {
    setFilteredPatientsList(patientsList);
  }, [patientsList])

  useEffect(() => {
    if (sampleState === '') {
      setFilteredPatientsList(patientsList);
    }
    if (filterState === 'fullName') {
      if (sampleState.length) {
        setFilteredPatientsList(patientsList.filter((item: any) =>
          item.doctors_full_name.toLowerCase().includes(sampleState.toLowerCase())))
      }
    }
    if (filterState === 'telephone') {
      if (sampleState.length) {
        setFilteredPatientsList(patientsList.filter((item: any) =>
          item.contacts.includes(sampleState)))
      }
    }
  }, [sampleState])

  const optionsPatients = {
    fullName: dictionary['fullName'],
    telephone: dictionary['telephone']
  };

  return (
    <div className="Patients">
      <div className="Patients__block">
        <div className="Patients__content">
          <span className="Patients__title">
            {dictionary['patients']}
          </span>
          <div className="Patients__actions">
            <div className="Patients__search">
              <div className="Patients__filter">
                <span className="Patients__filter-title">
                  {dictionary['filterBy']}
                </span>
                <Select
                  view="primary"
                  calledFrom="Patients__filter"
                  value={filterState}
                  handlerOnChange={(eValue: string) => setFilterState(eValue)}
                  options={optionsPatients}
                />
              </div>
              <div className="Patients__contains">
                <span className="Patients__contains-title">
                  {dictionary['contains']}
                </span>
                <Input
                  type="text"
                  view="primary"
                  calledFrom="Patients__contains"
                  value={sampleState}
                  handlerOnChange={(value: string) => setSampleState(value)}
                />
              </div>
            </div>
            <button
              className="btn btn_primary Patients__add-patient"
              type="button"
              onClick={() => dispatch(setModalParameters(true, 'addingPatient'))}
            >
              {dictionary['addPatient']}
            </button>
          </div>
          <div className="Patients__table">
            <div className="Patients__table-thead">
              <span className="Patients__table-th Patients__table-column">
                №
              </span>
              <span className="Patients__table-th Patients__table-column">
                {dictionary['surname']}
              </span>
              <span className="Patients__table-th Patients__table-column">
                {dictionary['name']}
              </span>
              <span className="Patients__table-th Patients__table-column">
                {dictionary['middleName']}
              </span>
              <span className="Patients__table-th Patients__table-column">
                {dictionary['gender']}
              </span>
              <span className="Patients__table-th Patients__table-column">
                {dictionary['yearOfBirth']}
              </span>
              <span className="Patients__table-th Patients__table-column">
                {dictionary['telephone']}
              </span>
              <span className="Patients__table-th Patients__table-column">
                {dictionary['action']}
              </span>
            </div>
          </div>
          {filteredPatientsList ? (
            <div className="Patients__table">
              <div className="Patients__table-tbody">
                <ul className="Patients__table-list">
                  {filteredPatientsList.map((item: any, index: number) => (
                    <PatientsListItem
                      key={`Patients__table-list-item_${item.pk}`}
                      pk={item.pk}
                      number={index + 1}
                      doctorsFullName={item.doctors_full_name}
                      gender={item.gender}
                      byYear={item.byear}
                      contacts={item.contacts}
                      email={item.email}
                      handlerSetShowPatient={handlerSetShowPatient}
                    />
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <Loader/>
          )}
        </div>
      </div>
    </div>
  );
}

export default PatientsComponent;
