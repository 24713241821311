import './index.scss';
import React, {useEffect, useState} from 'react';
import {useLanguageDictionary} from '../../../utilities';
import {useDispatch, useSelector} from 'react-redux';
import {TPulseMeteringProps, TStorageProps} from '../../../types';
import {useFormik} from 'formik';
import {DateTime} from 'luxon';
import user from '../../../api/requests/patients';
import {setModalParameters, setPatientReceptions} from '../../../storage/actions';
import Input from '../../../components/ui/input';

const InspectionDataAutoComponent: React.FC = () => {
  const {modalOptions} = useSelector((storage: TStorageProps) => ({modalOptions: storage.common.modal.modalOptions}));
  const {patientsListItem} = useSelector((storage: TStorageProps) => ({patientsListItem: storage.patients.patientsListItem}));
  const {dictionary} = useLanguageDictionary();
  const dispatch = useDispatch();
  const {doctorsFullName, contacts} = patientsListItem;
  const [pulseMetering, setPulseMetering] = useState<TPulseMeteringProps>(undefined);
  const [smallIntestineState, setSmallIntestineState] = useState<number | string>('');
  const [heartState, setHeartState] = useState<number | string>('');
  const [gallbladderState, setGallbladderState] = useState<number | string>('');
  const [liverState, setLiverState] = useState<number | string>('');
  const [bladderState, setBladderState] = useState<number | string>('');
  const [leftKidneyState, setLeftKidneyState] = useState<number | string>('');
  const [colonState, setColonState] = useState<number | string>('');
  const [lungsState, setLungsState] = useState<number | string>('');
  const [stomachState, setStomachState] = useState<number | string>('');
  const [pancreasSpleenState, setPancreasSpleenState] = useState<number | string>('');
  const [capillaryState, setCapillaryState] = useState<number | string>('');
  const [rightKidneyState, setRightKidneyState] = useState<number | string>('');
  const [isButtonSaveVisible, setIsButtonSaveVisible] = useState<boolean>(false);
  const {
    atime,
    calc_results,
    pk
  } = modalOptions;

  const transformedModalOptions = {
    anamnesis: modalOptions.anamnesis === null ? '' : modalOptions.anamnesis,
    complaints: modalOptions.complaints === null ? '' : modalOptions.complaints,
    diagnosis: modalOptions.diagnosis === null ? '' : modalOptions.diagnosis,
    notes: modalOptions.notes === null ? '' : modalOptions.notes,
    pulse_desc: modalOptions.pulse_desc === null ? '' : modalOptions.pulse_desc,
    tongue_desc: modalOptions.tongue_desc === null ? '' : modalOptions.tongue_desc
  }

  const {
    anamnesis,
    complaints,
    diagnosis,
    notes,
    pulse_desc,
    tongue_desc
  } = transformedModalOptions;

  const validate = (values: any) => {
    let transformedModalOptionsString: string = `${transformedModalOptions.complaints} ${transformedModalOptions.anamnesis} ${transformedModalOptions.notes} ${transformedModalOptions.pulse_desc} ${transformedModalOptions.tongue_desc} ${transformedModalOptions.diagnosis}`;
    let formikOptionsString: string = `${values.complaints} ${values.anamnesis} ${values.notes} ${values.pulse_desc} ${values.tongue_desc} ${values.diagnosis}`;
    if (transformedModalOptionsString === formikOptionsString) {
      setIsButtonSaveVisible(false);
    } else {
      setIsButtonSaveVisible(true);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pk,
      complaints,
      anamnesis,
      notes,
      pulse_desc,
      tongue_desc,
      diagnosis
    },
    validate,
    onSubmit: (values: any) => {
      user.sendModifiedPatientConsultation(values)
        .then(
          (data: any) => data.json()
        )
        .then(
          () => {
            user.getPatientReceptions(patientsListItem.pk)
              .then(
                (data: any) => data.json()
              )
              .then(
                (data: any) => {
                  dispatch(setPatientReceptions(data));
                  dispatch(setModalParameters(false));
                }
              )
              .catch((error: any) => error);
          }
        )
        .catch((error: any) => error);
    }
  });

  useEffect(() => {
    user.getAutomaticGraphicKey(calc_results[calc_results.length - 1])
      .then(
        (data: any) => data.json()
      )
      .then(
        (data: any) => {
          setPulseMetering(data);
        }
      )
      .catch((error: any) => error);
  }, [calc_results]);

  useEffect(() => {
    if (pulseMetering) {
      for (let i = 0; i < pulseMetering.organs.length; i++) {
        (pulseMetering.organs[i].name === 'small intestine') && setSmallIntestineState(pulseMetering.organs[i].state);
        (pulseMetering.organs[i].name === 'heart') && setHeartState(pulseMetering.organs[i].state);
        (pulseMetering.organs[i].name === 'gallbladder') && setGallbladderState(pulseMetering.organs[i].state);
        (pulseMetering.organs[i].name === 'liver') && setLiverState(pulseMetering.organs[i].state);
        (pulseMetering.organs[i].name === 'bladder') && setBladderState(pulseMetering.organs[i].state);
        (pulseMetering.organs[i].name === 'left kidney') && setLeftKidneyState(pulseMetering.organs[i].state);
        (pulseMetering.organs[i].name === 'colon') && setColonState(pulseMetering.organs[i].state);
        (pulseMetering.organs[i].name === 'lungs') && setLungsState(pulseMetering.organs[i].state);
        (pulseMetering.organs[i].name === 'stomach') && setStomachState(pulseMetering.organs[i].state);
        (pulseMetering.organs[i].name === 'pancreas spleen') && setPancreasSpleenState(pulseMetering.organs[i].state);
        (pulseMetering.organs[i].name === 'capillary') && setCapillaryState(pulseMetering.organs[i].state);
        (pulseMetering.organs[i].name === 'right kidney') && setRightKidneyState(pulseMetering.organs[i].state);
      }
    }
  }, [pulseMetering]);

  return (
    <>
      <span className="modal__title">
        {dictionary['inspectionData']}
      </span>
      <div className="InspectionDataAuto">
        <div className="InspectionDataAuto__block">
          <div className="InspectionDataAuto__content">
            <form onSubmit={formik.handleSubmit}>
              <div className="InspectionDataAuto__form">
                <div className="InspectionDataAuto__heading">
                  <div className="InspectionDataAuto__heading-point">
                    <span className="modal__label InspectionDataAuto__heading-point-label">
                      {dictionary['patient']}
                    </span>
                    <div className="InspectionDataAuto__heading-point-value">
                      {doctorsFullName.substring(0, doctorsFullName.indexOf('_'))} {doctorsFullName.substring(doctorsFullName.indexOf('_') + 1, doctorsFullName.lastIndexOf('_'))} {doctorsFullName.substring(doctorsFullName.length, doctorsFullName.lastIndexOf('_') + 1)}
                    </div>
                  </div>
                  <div className="InspectionDataAuto__heading-point">
                    <span className="modal__label InspectionDataAuto__heading-point-label">
                      {dictionary['telephone']}
                    </span>
                    <div className="InspectionDataAuto__heading-point-value">
                      {contacts}
                    </div>
                  </div>
                  <div className="InspectionDataAuto__heading-point">
                    <span className="modal__label InspectionDataAuto__heading-point-label">
                      {dictionary['dateOfInspection']}
                    </span>
                    <div className="InspectionDataAuto__heading-point-value">
                      {DateTime.fromISO(atime, {zone: "utc"}).toLocal().toFormat('dd/MM/yyyy')} {DateTime.fromISO(atime, {zone: "utc"}).toLocal().toFormat('HH:mm')}
                    </div>
                  </div>
                </div>
                <div className="InspectionDataAuto__fields">
                  <div className="InspectionDataAuto__fields-point">
                    <span className="modal__label InspectionDataAuto__fields-point-label">
                      {dictionary['mainComplaints']}
                    </span>
                    <textarea
                      className="textarea textarea_primary InspectionDataAuto__fields-point-textarea"
                      placeholder={dictionary['mainComplaints']}
                      value={formik.values.complaints}
                      onChange={(e: any) => formik.setFieldValue('complaints', e.target.value)}
                    />
                  </div>
                  <div className="InspectionDataAuto__fields-point">
                    <span className="modal__label InspectionDataAuto__fields-point-label">
                      {dictionary['anamnesis']}
                    </span>
                    <textarea
                      className="textarea textarea_primary InspectionDataAuto__fields-point-textarea"
                      placeholder={dictionary['anamnesis']}
                      value={formik.values.anamnesis}
                      onChange={(e: any) => formik.setFieldValue('anamnesis', e.target.value)}
                    />
                  </div>
                  <div className="InspectionDataAuto__fields-point">
                    <span className="modal__label InspectionDataAuto__fields-point-label">
                      {dictionary['inspectionData']}
                    </span>
                    <textarea
                      className="textarea textarea_primary InspectionDataAuto__fields-point-textarea"
                      placeholder={dictionary['inspectionData']}
                      value={formik.values.notes}
                      onChange={(e: any) => formik.setFieldValue('notes', e.target.value)}
                    />
                  </div>
                  <div className="InspectionDataAuto__fields-point">
                    <span className="modal__label InspectionDataAuto__fields-point-label">
                      {dictionary['pulseType']}
                    </span>
                    <textarea
                      className="textarea textarea_primary InspectionDataAuto__fields-point-textarea"
                      placeholder={dictionary['pulseType']}
                      value={formik.values.pulse_desc}
                      onChange={(e: any) => formik.setFieldValue('pulse_desc', e.target.value)}
                    />
                  </div>
                  <div className="InspectionDataAuto__fields-point">
                    <span className="modal__label InspectionDataAuto__fields-point-label">
                      {dictionary['tongueView']}
                    </span>
                    <textarea
                      className="textarea textarea_primary InspectionDataAuto__fields-point-textarea"
                      placeholder={dictionary['tongueView']}
                      value={formik.values.tongue_desc}
                      onChange={(e: any) => formik.setFieldValue('tongue_desc', e.target.value)}
                    />
                  </div>
                  <div className="InspectionDataAuto__fields-point">
                    <span className="modal__label InspectionDataAuto__fields-point-label">
                      {dictionary['diagnosisAndAppointments']}
                    </span>
                    <textarea
                      className="textarea textarea_primary InspectionDataAuto__fields-point-textarea"
                      placeholder={dictionary['diagnosisAndAppointments']}
                      value={formik.values.diagnosis}
                      onChange={(e: any) => formik.setFieldValue('diagnosis', e.target.value)}
                    />
                  </div>
                  <div className="InspectionDataAuto__fields-point">
                    <span className="modal__label InspectionDataAuto__fields-point-label">
                      {dictionary['organsCondition']}
                    </span>
                    <div className="InspectionDataAuto__fields-point-area">
                      <div className="InspectionDataAuto__fields-point-column">
                        <span className="InspectionDataAuto__fields-point-column-title">
                          {dictionary['organsOfTheLeftHand']}
                        </span>
                        <div className="InspectionDataAuto__fields-point-column-box">
                          <div className="InspectionDataAuto__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataAuto__fields-point-column-sublabel">
                              {dictionary['colonTitle_12']}
                            </span>
                            <div className="InspectionDataAuto__fields-point-column-value">
                              <Input
                                type="text"
                                view="primary"
                                calledFrom="InspectionDataAuto__fields-point-column"
                                value={colonState}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="InspectionDataAuto__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataAuto__fields-point-column-sublabel">
                              {dictionary['lungsTitle_12']}
                            </span>
                            <div className="InspectionDataAuto__fields-point-column-value">
                              <Input
                                type="text"
                                view="primary"
                                calledFrom="InspectionDataAuto__fields-point-column"
                                value={lungsState}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="InspectionDataAuto__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataAuto__fields-point-column-sublabel">
                              {dictionary['gallbladderTitle_12']}
                            </span>
                            <div className="InspectionDataAuto__fields-point-column-value">
                              <Input
                                type="text"
                                view="primary"
                                calledFrom="InspectionDataAuto__fields-point-column"
                                value={gallbladderState}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="InspectionDataAuto__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataAuto__fields-point-column-sublabel">
                              {dictionary['liverTitle_12']}
                            </span>
                            <div className="InspectionDataAuto__fields-point-column-value">
                              <Input
                                type="text"
                                view="primary"
                                calledFrom="InspectionDataAuto__fields-point-column"
                                value={liverState}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="InspectionDataAuto__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataAuto__fields-point-column-sublabel">
                              {dictionary['bladderTitle_12']}
                            </span>
                            <div className="InspectionDataAuto__fields-point-column-value">
                              <Input
                                type="text"
                                view="primary"
                                calledFrom="InspectionDataAuto__fields-point-column"
                                value={bladderState}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="InspectionDataAuto__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataAuto__fields-point-column-sublabel">
                              {dictionary['leftKidneyTitle_12']}
                            </span>
                            <div className="InspectionDataAuto__fields-point-column-value">
                              <Input
                                type="text"
                                view="primary"
                                calledFrom="InspectionDataAuto__fields-point-column"
                                value={leftKidneyState}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="InspectionDataAuto__fields-point-column">
                        <span className="InspectionDataAuto__fields-point-column-title">
                          {dictionary['organsOfTheRightHand']}
                        </span>
                        <div className="InspectionDataAuto__fields-point-column-box">
                          <div className="InspectionDataAuto__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataAuto__fields-point-column-sublabel">
                              {dictionary['smallIntestineTitle_12']}
                            </span>
                            <div className="InspectionDataAuto__fields-point-column-value">
                              <Input
                                type="text"
                                view="primary"
                                calledFrom="InspectionDataAuto__fields-point-column"
                                value={smallIntestineState}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="InspectionDataAuto__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataAuto__fields-point-column-sublabel">
                              {dictionary['heartTitle_12']}
                            </span>
                            <div className="InspectionDataAuto__fields-point-column-value">
                              <Input
                                type="text"
                                view="primary"
                                calledFrom="InspectionDataAuto__fields-point-column"
                                value={heartState}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="InspectionDataAuto__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataAuto__fields-point-column-sublabel">
                              {dictionary['stomachTitle_12']}
                            </span>
                            <div className="InspectionDataAuto__fields-point-column-value">
                              <Input
                                type="text"
                                view="primary"
                                calledFrom="InspectionDataAuto__fields-point-column"
                                value={stomachState}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="InspectionDataAuto__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataAuto__fields-point-column-sublabel">
                              {dictionary['pancreasSpleenTitle_12']}
                            </span>
                            <div className="InspectionDataAuto__fields-point-column-value">
                              <Input
                                type="text"
                                view="primary"
                                calledFrom="InspectionDataAuto__fields-point-column"
                                value={pancreasSpleenState}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="InspectionDataAuto__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataAuto__fields-point-column-sublabel">
                              {dictionary['capillaryTitle_12']}
                            </span>
                            <div className="InspectionDataAuto__fields-point-column-value">
                              <Input
                                type="text"
                                view="primary"
                                calledFrom="InspectionDataAuto__fields-point-column"
                                value={capillaryState}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="InspectionDataAuto__fields-point-column-line">
                            <span className="modal__sublabel InspectionDataAuto__fields-point-column-sublabel">
                              {dictionary['rightKidneyTitle_12']}
                            </span>
                            <div className="InspectionDataAuto__fields-point-column-value">
                              <Input
                                type="text"
                                view="primary"
                                calledFrom="InspectionDataAuto__fields-point-column"
                                value={rightKidneyState}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="InspectionDataAuto__info">
                  <div className="InspectionDataAuto__info-point">
                    <span className="modal__label InspectionDataAuto__info-point-label">
                      {dictionary['measurementType']}
                    </span>
                    <span className="InspectionDataAuto__info-point-value">
                      {dictionary['auto']}
                    </span>
                  </div>
                  <div className="InspectionDataAuto__info-point">
                    <span className="modal__label InspectionDataAuto__info-point-label">
                      {dictionary['label']}
                    </span>
                    <span className="InspectionDataAuto__info-point-value">
                      {dictionary[`${pulseMetering && pulseMetering.description.mark}`]}
                    </span>
                  </div>
                  <div className="InspectionDataAuto__info-point">
                    <span className="modal__label InspectionDataAuto__info-point-label">
                      {dictionary['device']}
                    </span>
                    <span className="InspectionDataAuto__info-point-value">
                      {pulseMetering && pulseMetering.device_info.manufacturer} {pulseMetering && pulseMetering.device_info.model}
                    </span>
                  </div>
                  {/*<div className="InspectionDataAuto__info-point">*/}
                  {/*  <span className="modal__label InspectionDataAuto__info-point-label">*/}
                  {/*    {dictionary['softwareVersion']}*/}
                  {/*  </span>*/}
                  {/*  <span className="InspectionDataAuto__info-point-value">*/}
                  {/*    Arduino UNO*/}
                  {/*  </span>*/}
                  {/*</div>*/}
                  {/*<div className="InspectionDataAuto__info-point">*/}
                  {/*  <span className="modal__label InspectionDataAuto__info-point-label">*/}
                  {/*    {dictionary['OSVersion']}*/}
                  {/*  </span>*/}
                  {/*  <span className="InspectionDataAuto__info-point-value">*/}
                  {/*    Arduino UNO 1.0*/}
                  {/*  </span>*/}
                  {/*</div>*/}
                </div>
                {isButtonSaveVisible && (
                  <button
                    className="btn btn_primary InspectionDataAuto__save"
                    type="submit"
                  >
                    {dictionary['save']}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default InspectionDataAutoComponent;
