import './index.scss';
import React from 'react';
import {Link} from 'react-router-dom';
import PAGE_ROUTES from '../../../router/routes';
import {switchMatch, useLanguageDictionary} from '../../../utilities';
import {useDispatch, useSelector} from 'react-redux';
import {TStorageProps} from '../../../types';
import ReactFlagsSelect from 'react-flags-select';
import {setLanguageDictionary} from '../../../storage/actions';
import icons from '../../../assets/images/icons/icons.json';
import SVGInline from 'react-svg-inline';
import {setIsUserAuthorized} from '../../../storage/actions/user';

const AsideComponent: React.FC = () => {
  const {isUserAuthorized} = useSelector((storage: TStorageProps) => ({isUserAuthorized: storage.user.isUserAuthorized}));
  const {selectedUserLanguage} = useSelector((storage: TStorageProps) => ({selectedUserLanguage: storage.common.languageDictionary.selectedUserLanguage}));
  const {dictionary} = useLanguageDictionary();
  const dispatch = useDispatch();

  return (
    <aside className="Aside">
      <div className="Aside__block">
        <div className="Aside__content">
          <div className="Aside__logotype">
            <Link
              className="Aside__link"
              to={isUserAuthorized ? PAGE_ROUTES.DESKTOP : PAGE_ROUTES.SIGN_IN}
            >
              <span className="Aside__logotype-image"/>
            </Link>
            {!isUserAuthorized && (
              <Link
                className="Aside__link"
                to={isUserAuthorized ? PAGE_ROUTES.DESKTOP : PAGE_ROUTES.SIGN_IN}
              >
                <span className="Aside__logotype-title">
                  Omnio
                </span>
                <span className="Aside__logotype-subtitle">
                  {dictionary['doctor\'sOffice']}
                </span>
              </Link>
            )}
          </div>
          {isUserAuthorized && (
            <div className="Aside__actions">
              <div className="Aside__language-selection">
                <ReactFlagsSelect
                  selectButtonClassName={
                    switchMatch(selectedUserLanguage, {
                      en: 'US',
                      zh: 'CN',
                      default: selectedUserLanguage
                    }).toUpperCase()}
                  placeholder={dictionary['selectLanguage']}
                  selected={
                    switchMatch(selectedUserLanguage, {
                      en: 'US',
                      zh: 'CN',
                      default: selectedUserLanguage
                    }).toUpperCase()
                  }
                  countries={["US", "RU", "CN"]}
                  customLabels={{"US": "English", "RU": "Русский", "CN": "中文"}}
                  onSelect={(countryCode: string) => {
                    dispatch(setLanguageDictionary(countryCode.toLocaleLowerCase()))
                  }}
                />
              </div>
              <button
                className="Aside__exit-button"
                onClick={() => {
                  localStorage.removeItem('userBackendDomain');
                  dispatch(setIsUserAuthorized(false))
                }}
              >
                <SVGInline
                  className="SVGInline_stroke"
                  svg={`${icons['exit']}`}
                />
                <span className="Aside__exit-button-text">
                  {dictionary['output']}
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </aside>
  );
}

export default AsideComponent;
