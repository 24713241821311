import './index.scss';
import React from 'react';
import {checkingMailForValidity, useLanguageDictionary} from '../../../../utilities';
import {useFormik} from 'formik';
import Input from '../../../../components/ui/input';
import authentication from '../../../../api/requests/authentication';
import {useDispatch} from 'react-redux';
import {setIsUserAuthorized} from '../../../../storage/actions/user';

const SignInFormComponent: React.FC = () => {
  const {dictionary} = useLanguageDictionary();
  const dispatch = useDispatch();
  const validate = (values: { login: string, password: string }) => {
    const errors: any = {};
    if (!values.login) {
      errors.login = dictionary['requiredFields'];
    }
    if (values.password.length < 7) {
      errors.password = dictionary['passMustBeCharactersMustNotContainSpaces'];
    }
    if (values.password.indexOf(' ') > -1) {
      errors.password = dictionary['passwordMustNotContainSpaces'];
    }
    if (values.password.length > 60) {
      errors.password = dictionary['maximumLengthCharacters'];
    }
    if (values.login.length > 60) {
      errors.login = dictionary['maximumLengthCharacters'];
    }
    if (!checkingMailForValidity(values.login)) {
      errors.login = dictionary['theLoginMustMatchTheEmailTemplate'];
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validate,
    onSubmit: ({login, password}, {setSubmitting, setErrors}) => {
      authentication.login(login, password)
        .then(
          (data: any) => data.json(),
          () => {
            setErrors({
              login: dictionary['wrongLoginOrPassword'],
            });
            setSubmitting(false);
          }
        )
        .then(
          (data: any) => {
            localStorage.setItem('userBackendDomain', data.back);
            dispatch(setIsUserAuthorized(true));
          }
        )
        .catch((error: any) => error);
    }
  });

  return (
    <div className="SignInForm">
      <div className="SignInForm__block">
        <div className="SignInForm__content">
          <div className="SignInForm__self">
            <form onSubmit={formik.handleSubmit}>
              <div className="SignInForm__fields">
                <div className="SignInForm__fields-point">
                  <Input
                    icon
                    type="email"
                    view="primary"
                    calledFrom="SignInForm__fields-point"
                    placeholder={dictionary['enterEmail']}
                    error={formik.errors.login}
                    value={formik.values.login}
                    handlerOnChange={(value: string) => {
                      formik.setFieldValue('login', value)
                    }}
                  />
                </div>
                <div className="SignInForm__fields-point">
                  <Input
                    icon
                    type="password"
                    view="primary"
                    calledFrom="SignInForm__fields-point"
                    placeholder={dictionary['enterPassword']}
                    error={formik.errors.password}
                    value={formik.values.password}
                    handlerOnChange={(value: string) => formik.setFieldValue('password', value)}
                  />
                </div>
              </div>
              <div className="SignInForm__actions">
                <button
                  className="btn btn_primary SignInForm__actions-item"
                  type="submit"
                >
                  {dictionary['entrance']}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignInFormComponent;