import './index.scss';
import React from 'react';
import {switchMatch, useLanguageDictionary} from '../../../utilities';
import {useDispatch, useSelector} from 'react-redux';
import {setModalParameters} from '../../../storage/actions';
import {TStorageProps} from '../../../types';
import {DateTime} from 'luxon';
import Input from '../../ui/input';
import user from '../../../api/requests/patients';

const AnalysisDataComponent: React.FC = () => {
  const {modalOptions} = useSelector((storage: TStorageProps) => ({modalOptions: storage.common.modal.modalOptions}));
  const {patientsListItem} = useSelector((storage: TStorageProps) => ({patientsListItem: storage.patients.patientsListItem}));
  const {dictionary} = useLanguageDictionary();
  const dispatch = useDispatch();
  const {doctorsFullName, contacts} = patientsListItem;
  const {
    description,
    device_info,
    id,
    organs,
    time,
  } = modalOptions;

  const handlerOnClick = () => {
    let pk: any;
    user.sendNewPatientConsultation({
      patient_id: patientsListItem.pk
    })
      .then(
        (data: any) => data.json()
      )
      .then(
        (data: any) => {
          pk = data.pk;
          user.sendPatientAnalyze({
            analyze_id: id,
            pk: data.pk
          })
            .then(
              (data: any) => data.json()
            )
            .then(
              (data: any) => {
                let organs = data.organs;
                dispatch(setModalParameters(false));
                setTimeout(() => {
                  dispatch(setModalParameters(true, 'newReport', {pk, id, organs, description, device_info}));
                });
              }
            )
            .catch((error: any) => error);
        }
      )
      .catch((error: any) => error);
  }

  return (
    <>
      <span className="modal__title">
        {dictionary['analysisData']}
      </span>
      <div className="AnalysisData">
        <div className="AnalysisData__block">
          <div className="AnalysisData__content">
            <div className="AnalysisData__heading">
              <div className="AnalysisData__heading-point">
                <span className="modal__label AnalysisData__heading-point-label">
                  {dictionary['patient']}
                </span>
                <div className="AnalysisData__heading-point-value">
                  {doctorsFullName.substring(0, doctorsFullName.indexOf('_'))} {doctorsFullName.substring(doctorsFullName.indexOf('_') + 1, doctorsFullName.lastIndexOf('_'))} {doctorsFullName.substring(doctorsFullName.length, doctorsFullName.lastIndexOf('_') + 1)}
                </div>
              </div>
              <div className="AnalysisData__heading-point">
                <span className="modal__label AnalysisData__heading-point-label">
                  {dictionary['telephone']}
                </span>
                <div className="AnalysisData__heading-point-value">
                  {contacts}
                </div>
              </div>
              <div className="AnalysisData__heading-point">
                <span className="modal__label AnalysisData__heading-point-label">
                  {dictionary['analysisDate']}
                </span>
                <div className="AnalysisData__heading-point-value">
                  {DateTime.fromISO(time, {zone: "utc"}).toLocal().toFormat('dd/MM/yyyy')} {DateTime.fromISO(time, {zone: "utc"}).toLocal().toFormat('HH:mm')}
                </div>
              </div>
            </div>
            <div className="AnalysisData__fields">
              <div className="AnalysisData__fields-point">
                <span className="modal__label AnalysisData__fields-point-label">
                  {dictionary['label']}
                </span>
                <span className="AnalysisData__fields-point-value">
                   {dictionary[`${description.mark}`]}
                </span>
              </div>
              <div className="AnalysisData__fields-point">
                <span className="modal__label AnalysisData__fields-point-label">
                  {dictionary['device']}
                </span>
                <span className="AnalysisData__fields-point-value">
                  {device_info && device_info.manufacturer && device_info.manufacturer} {device_info && device_info.model && device_info.model}
                </span>
              </div>
              {/*<div className="AnalysisData__fields-point">*/}
              {/*  <span className="modal__label AnalysisData__fields-point-label">*/}
              {/*    {dictionary['softwareVersion']}*/}
              {/*  </span>*/}
              {/*  <span className="AnalysisData__fields-point-value">*/}
              {/*    Arduino UNO*/}
              {/*  </span>*/}
              {/*</div>*/}
              {/*<div className="AnalysisData__fields-point">*/}
              {/*  <span className="modal__label AnalysisData__fields-point-label">*/}
              {/*    {dictionary['OSVersion']}*/}
              {/*  </span>*/}
              {/*  <span className="AnalysisData__fields-point-value">*/}
              {/*    Arduino UNO 1.0*/}
              {/*  </span>*/}
              {/*</div>*/}
              <div className="AnalysisData__fields-point">
                <span className="modal__label AnalysisData__fields-point-label">
                  {dictionary['organsCondition']}
                </span>
                <div className="AnalysisData__fields-point-list">
                  {!organs && (
                    <div className="AnalysisData__fields-point-list-line">
                      <span className="modal__sublabel AnalysisData__fields-point-list-sublabel">
                        {dictionary['AnalysisWasNotPerformed']}
                      </span>
                    </div>
                  )}
                  {organs && organs.length === 12 && (
                    organs.map((organ: any, index: number) => (
                      <div
                        key={`AnalysisData__fields-point-list-line_${index}`}
                        className="AnalysisData__fields-point-list-line"
                      >
                        <span className="modal__sublabel AnalysisData__fields-point-list-sublabel">
                          {switchMatch(organs[index].name, {
                            'small intestine': dictionary['smallIntestineTitle_12'],
                            'heart': dictionary['heartTitle_12'],
                            'gallbladder': dictionary['gallbladderTitle_12'],
                            'liver': dictionary['liverTitle_12'],
                            'bladder': dictionary['bladderTitle_12'],
                            'left kidney': dictionary['leftKidneyTitle_12'],
                            'colon': dictionary['colonTitle_12'],
                            'lungs': dictionary['lungsTitle_12'],
                            'stomach': dictionary['stomachTitle_12'],
                            'pancreas spleen': dictionary['pancreasSpleenTitle_12'],
                            'capillary': dictionary['capillaryTitle_12'],
                            'right kidney': dictionary['rightKidneyTitle_12']
                          })}
                        </span>
                        <div className="AnalysisData__fields-point-list-value">
                          <Input
                            type="text"
                            view="primary"
                            calledFrom="InspectionDataAuto__fields-point-column"
                            value={organs[index].state}
                            disabled
                          />
                        </div>
                      </div>
                    ))
                  )}
                  {organs && organs.length <= 7 && (
                    organs.map((organ: any, index: number) => (
                      <div
                        key={`AnalysisData__fields-point-list-line_${index}`}
                        className="AnalysisData__fields-point-list-line"
                      >
                        <span className="modal__sublabel AnalysisData__fields-point-list-sublabel">
                          {switchMatch(organs[index].name, {
                            'heart': dictionary['heartTitle_6'],
                            'liver': dictionary['liverTitle_6'],
                            'kidney': dictionary['kidneyTitle_6'],
                            'lungs': dictionary['lungsTitle_6'],
                            'stomach': dictionary['stomachTitle_6'],
                            'capillary': dictionary['capillaryTitle_6']
                          })}
                        </span>
                        <div className="AnalysisData__fields-point-list-value">
                          <Input
                            type="text"
                            view="primary"
                            calledFrom="InspectionDataAuto__fields-point-column"
                            value={organs[index].state}
                            disabled
                          />
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            <button
              className="btn btn_primary AnalysisData__create-report"
              type="button"
              onClick={() => handlerOnClick()}
            >
              {dictionary['createReport']}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AnalysisDataComponent;
